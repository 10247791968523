import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ForgotPasswordApi } from "../services/UserServices";
import Swal from "sweetalert2";
import { useContext } from "react";
import { GlobalContext } from "../context/GlobalContext";
import { Link } from "react-router-dom";

export const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();
  const globalContext = useContext(GlobalContext);

  const forgotPass = (data) => {
    globalContext.setLoader(true);
    data["email"] = data.email.toLowerCase()
    ForgotPasswordApi(data)
      .then((res) => {
        globalContext.setLoader(false);
        Swal.fire(
          "Success",
          "Password reset link has been sent to your email.",
          "success"
        );
        reset();
      })
      .catch((err) => {
        globalContext.setLoader(false);
        setError("email", { message: err.response.data.message });
        console.log("Error: ", err);
      });
  };

  return (
    <section className="signup-bot">
      <div className="login m-auto">
        <p className="text-center text-white pb-3">
          We will send you the password recovery instructions to your email.{" "}
        </p>
        <div className="tab-heading-area">
          <h1 className="mb-4"> Forgot Password?</h1>
        </div>
        <Form onSubmit={handleSubmit(forgotPass)}>
          <div className="row">
            <div className="login-data col-lg-12">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Enter Email" 
                  {...register("email", {
                    onChange: (e) => {
                      if (e.target.value === " ") {
                        setValue("email", "");
                      }
                    },
                    pattern: {
                      value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                      message: "Enter valid email address",
                    },
                    required: "Email is required",
                  })}
                />
                {errors.email && (
                  <span className="error-msg">{errors.email.message}</span>
                )}
              </Form.Group>
            </div>

            <div className="col-lg-12 login-data">
              <button className="btn btn-sign">Submit</button>
            </div>
          </div>
          <p className="signup-back-btn back-to-login">
            <Link to="/login">
              <img src={require("../assets/images/back-btn.png")} alt="back" />
              Back to Login
            </Link>
          </p>
        </Form>
      </div>
    </section>
  );
};
