import axios from "axios";
import { BASE_URL } from "../configuration/Config";
import { Headers } from "../configuration/ConfigHeaders";

function configHeaders() {
  return {
    headers: Headers(),
  };
}

export const SignUpUser = (data) => {
  let url = `${BASE_URL}/signup`;
  return axios.post(url, data);
};

export const LoginUser = (data) => {
  let url = `${BASE_URL}/login`;
  return axios.post(url, data);
};

export const GetSubscriptionsPlan = (type) => {
  let url = `${BASE_URL}/subscriptions?durationUnit=${type}`;
  return axios.get(url);
};

export const ParticularPlan = (id) => {
  let url = `${BASE_URL}/subscriptions/${id}`;
  return axios.get(url, configHeaders());
};

export const PurchaseSubscription = (data) => {
  let url = `${BASE_URL}/subscription/subscriptionPurchased`;
  return axios.post(url, data, configHeaders());
};

export const UserData = () => {
  let url = `${BASE_URL}/userData`;
  return axios.get(url, configHeaders());
};

export const ForgotPasswordApi = (params) => {
  let url = `${BASE_URL}/forgotPassword`;
  return axios.post(url, params);
};

export const ResetPasswordApi = (params) => {
  let url = `${BASE_URL}/resetPassword`;
  return axios.post(url, params);
};

export const CancelPlan = (params) => {
  let url = `${BASE_URL}/cancelSubscription`;
  return axios.post(url, params, configHeaders());
};

export const ChangePasswordApi = (params) => {
  let url = `${BASE_URL}/changePassword`;
  return axios.post(url, params, configHeaders());
};

export const MyTransactionsApi = (page) => {
  let url = `${BASE_URL}/getMyTransactions?page=${page}`;
  return axios.get(url, configHeaders());
};

export const EditUserProfile = (params) => {
  let url = `${BASE_URL}/editProfile`;
  return axios.patch(url, params, configHeaders());
};

export const VerifyUserEmail = (token) => {
  let url = `${BASE_URL}/verifyEmail`;
  return axios.post(url, token);
};

export const ResendEmailVerify = (params) => {
  let url = `${BASE_URL}/resendVerification`;
  return axios.post(url, params)
}
