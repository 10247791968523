import { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Privacy } from "../Component/Common/Privacy";
import { Terms } from "../Component/Common/Terms";
import { GlobalContext } from "../context/GlobalContext";
import { SignUpUser } from "../services/UserServices";

const SignUp = () => {
  const mailChimpU = "f58f6b906593afc22e25ccc42";
  const mailChimpId = "691fd9586b";
  const mailChimpLink = `https://thebotfather.us21.list-manage.com/subscribe/post?u=${mailChimpU}&id=${mailChimpId}`;

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    getValues,
    reset,
    formState: { errors },
  } = useForm();
  const globalContext = useContext(GlobalContext);
  const navigate = useNavigate();
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const userSignUp = (data) => {
    if (data.password !== data.confirmPassword) {
      setError("confirmPassword", {
        message: "Password and confirm password should be same",
      });
    } else {
      data["email"] = data.email.toLowerCase().trim();
      data["firstName"] = data.firstName.trim();
      data["lastName"] = data.lastName.trim();
      delete data.confirmPassword;
      delete data.subscribeLetter;
      delete data.terms;
      globalContext.setLoader(true);
      SignUpUser(data)
        .then((res) => {
          globalContext.setLoader(false);
          Swal.fire("Success", res.data.message, "success").then(() =>
            navigate("/login")
          );
          reset();
        })
        .catch((err) => {
          globalContext.setLoader(false);
          Swal.fire(err.response.data.message, "", "error");
          console.log("error: ", err);
        });
    }
  };

  return (
    <section className="signup-bot">
      <div className="tab-heading-area">
        <h1 className="mb-4">Sign up</h1>
      </div>
      <MailchimpSubscribe
        url={mailChimpLink}
        render={({ subscribe, status, message }) => (
          <Form onSubmit={handleSubmit(userSignUp)}>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                maxLength={15}
                placeholder="Enter First Name"
                {...register("firstName", {
                  onChange: (e) => {
                    if (e.target.value === " ") {
                      setValue("firstName", "");
                    }
                  },
                  required: "First name is required",
                })}
              />
              {errors.firstName && (
                <span className="error-msg">{errors.firstName.message}</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                maxLength={15}
                placeholder="Enter Last Name"
                {...register("lastName", {
                  onChange: (e) => {
                    if (e.target.value === " ") {
                      setValue("lastName", "");
                    }
                  },
                  required: "Last name is required",
                })}
              />
              {errors.lastName && (
                <span className="error-msg">{errors.lastName.message}</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Email"
                {...register("email", {
                  onChange: (e) => {
                    if (e.target.value === " ") {
                      setValue("email", "");
                    }
                  },
                  pattern: {
                    value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                    message: "Enter valid email address",
                  },
                  required: "Email is required",
                })}
              />
              {errors.email && (
                <span className="error-msg">{errors.email.message}</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Password"
                {...register("password", {
                  onChange: (e) => {
                    if (e.target.value === " ") {
                      setValue("password", "");
                    }
                  },
                  required: "Password is required",
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])(?!.*\s).{8,}$/,
                    message:
                      "Password must contain 1 lower case, 1 upper case, 1 number and 1 special character.",
                  },
                  minLength: {
                    value: 8,
                    message: " Password must be at least 8 characters long",
                  },
                })}
              />
              {errors.password && (
                <span className="error-msg">{errors.password.message}</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Confirm Password"
                {...register("confirmPassword", {
                  onChange: (e) => {
                    if (e.target.value === " ") {
                      setValue("confirmPassword", "");
                    }
                  },
                  required: "Confirm password is required",
                })}
              />
              {errors.confirmPassword && (
                <span className="error-msg">
                  {errors.confirmPassword.message}
                </span>
              )}
            </Form.Group>
            <div className="agree-condition">
              <input
                type="checkbox"
                id="term"
                {...register("terms", {
                  required: "Please agree to terms and conditions",
                })}
              />
              <label htmlFor="term">
                I've read and agree with Botfather{" "}
                <span className="view-reason" onClick={() => setShowTerms(true)}>Terms</span> and{" "}
                <span className="view-reason" onClick={() => setShowPrivacy(true)}>Privacy Policy</span>
                .
              </label>
            </div>
            {errors.terms && (
              <span className="error-msg">{errors.terms.message}</span>
            )}
            <div className="agree-condition">
              <input
                type="checkbox"
                id="letter"
                {...register("subscribeLetter")}
              />
              <label htmlFor="letter">
                I would like to subscribe to the newsletter.
              </label>
            </div>
            <Button
              variant="unset"
              type="submit"
              className="submit-btn"
              onClick={() => {
                if (
                  getValues("subscribeLetter") &&
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                    getValues("email")
                  )
                ) {
                  subscribe({
                    EMAIL: getValues("email"),
                  });
                }
              }}
            >
              Sign up
            </Button>
            <p className="signup-back-btn back-to-login">
              <Link to="/login">
                <img
                  src={require("../assets/images/back-btn.png")}
                  alt="back"
                />
                Back to Login
              </Link>
            </p>
          </Form>
        )}
      />
      <Terms show={showTerms} onHide={() => setShowTerms(false)} />
      <Privacy show={showPrivacy} onHide={() => setShowPrivacy(false)} />
    </section>
  );
};
export default SignUp;
