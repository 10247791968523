import Modal from "react-bootstrap/Modal";

export const Privacy = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-view"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          PRIVACY POLICY
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-3">
          <b>General Provisions:</b>
          <p>
            Botfather LLC ("we", "our", "us") is committed to respecting your
            privacy and recognizes the need for appropriate protection and
            management of any personal data you share with us. This Privacy
            Policy explains our practices regarding the collection, use, and
            disclosure of your personal data. We are the data controller for
            personal data within the scope of this Privacy Policy. For the
            purposes of this document, “The/Our Software” is defined as the
            complete set of services that Botfather LLC provides to active
            customers.
          </p>
          <br />
          <p>
            Please note, this Privacy Policy applies only to our software
            activities and is valid for users who share their personal data with
            us through our software. This policy is not applicable to any
            information collected offline or via channels other than our
            software.
          </p>
          <br />
          <p>
            If you have any questions about our Privacy Policy or our data
            practices, do not hesitate to contact us at{" "}
            <a href="mailto:info@thebotfather.io">info@thebotfather.io</a>
          </p>
          <br />
          <b>Your Consent:</b>
          <p>
            By using our software, you hereby consent to our Privacy Policy and
            agree to its terms. IF YOU DO NOT AGREE WITH THE TERMS OF THIS
            PRIVACY POLICY, DO NOT ACCESS OR USE OUR SOFTWARE.
          </p>
          <br />
          <b>Information for EU and UK Users</b>
          <p>
            If you are a resident of the European Union (EU) or the United
            Kingdom (UK), you have certain rights and protections under the law
            regarding the processing of your personal data. We aim to process
            your data in accordance with the General Data Protection Regulation
            (GDPR).
          </p>
          <br />
          <p>
            Please read this Privacy Policy carefully to understand our policies
            and practices regarding your data and how we will treat it.
          </p>
          <br />
          <p>
            We reserve the right to update our Privacy Policy at any time. Thus,
            we advise you to review this page periodically for any changes. We
            will notify you of any changes by posting the new Privacy Policy on
            this page. These changes are effective immediately, after they are
            posted on this page.
          </p>
          <br />
          <b>Changes to the Policy:</b>
          <p>
            Botfather LLC may update this Privacy Policy at any time in response
            to changing legal, technical, or business developments. When we
            update our Privacy Policy, we will take appropriate measures to
            inform you, consistent with the significance of the changes we make.
          </p>
          <br />
          <p>
            We will obtain your consent to any material Privacy Policy changes
            if and where this is required by applicable data protection laws.
            You will be notified of any changes via an update on our Software,
            and the revised Privacy Policy will be posted on{" "}
            <i>Thebotfather.io</i> with an updated revision date.
          </p>
          <br />
          <p>
            Any changes to this Privacy Policy will take effect on the date they
            are published, or as otherwise required by law. Please check back
            regularly to stay informed of updates or changes to this Privacy
            Policy.
          </p>
          <br />
          <p>
            Your continued use of the Software after any change in this Privacy
            Policy will constitute your acceptance of such change. If you do not
            agree with any changes to the Privacy Policy, your sole remedy is to
            cease using the Software.
          </p>
          <br />
          <p>
            Please note, you do not have to inform us of this decision unless
            you intend to exercise your data protection rights under applicable
            data protection laws, including the General Data Protection
            Regulation (GDPR) for EU users, as detailed further in this Privacy
            Policy.
          </p>
          <br />
          <b>Eligibility:</b>
          <p>
            <b>Age Restriction.</b> The Software is not intended for individuals
            under the age of 18. By accessing or using the Software, you
            represent, warrant, and affirm that you are at least 18 years of age
            or the age of legal majority in your jurisdiction, whichever is
            greater.
          </p>
          <br />
          <p>
            <b>Compliance.</b> By using the Software, you represent and warrant
            that you will comply with all applicable laws and regulations,
            including those related to data privacy, international
            communications, and the transmission of technical or personal data.
          </p>
          <br />
          <p>
            <b>Unauthorized Use.</b> Any use of the Software by individuals
            under the age of 18 is strictly prohibited and in violation of this
            Privacy Policy. We do not knowingly collect or solicit personal
            information from individuals under 18 or knowingly allow such
            individuals to register for an account. If you are under 18, please
            do not attempt to register or send any information about yourself to
            us, including your name, address, email address, or phone number.
          </p>
          <br />
          <p>
            <b>Reporting.</b> If you become aware of any use of our Software by
            individuals under the age of 18, or any violations of this
            Eligibility section, please contact us immediately at
            <a href="mailto:info@thebotfather.io">info@thebotfather.io</a>.
          </p>
          <br />
          <b>Policy:</b>
          <b>1. Applicability and Data Processing Partners</b>
          <br />
          <p>
            This Privacy Policy applies to all your interactions with us via the
            Software and any associated services provided by Botfather LLC.
          </p>
          <br />
          <p>
            We utilize certain third-party service providers to assist us in
            data processing activities. These service providers only have access
            to the data they need to perform their functions and are
            contractually bound to maintain the confidentiality and security of
            your information. The categories of our data processing partners
            that may access and process your Personal Data include, but are not
            limited to:
          </p>
          <br />
          <p>1. Technical maintenance providers;</p>
          <p>2. Project management and team collaboration tools;</p>
          <p>3. Delivery service providers for any merchandise products;</p>
          <p>4. Communication service providers;</p>
          <p>
            5. Providers of analytics, statistics, performance measurement, and
            marketing services.
          </p>
          <br />
          <p>
            If you have any questions or wish to receive more information about
            our data processing activities, please reach out to us at
            <a href="mailto:info@thebotfather.io">info@thebotfather.io</a>.
          </p>
          <br />
          <b>2. Categories of Data Collected</b>
          <br />
          <p>
            Botfather LLC collects and uses personal data as necessary in the
            performance of our services. Personal Data collected and used may
            include:
          </p>
          <br />
          <p>
            1. Email address, name, and other data provided through
            communication, only used for correspondence.
          </p>
          <br />
          <p>
            2. IP address, MAC address, log files, domain server, usage and
            performance data, website security information, traffic patterns,
            location data, browser, and device information when using the
            Software.
          </p>
          <br />
          <p>
            3. Public blockchain addresses (wallet addresses), transaction, and
            balance information. This data is used for user experience
            improvement. We do not use this data at an individual user level.
            The legal basis for this processing is our legitimate interests,
            such as monitoring and improving the Software and ensuring its
            security.
          </p>
          <br />
          <p>
            4. Log Files: Like many other websites, we use log files. The
            information inside the log files includes IP addresses, type of
            browser, Internet Service Provider (ISP), date/time stamp,
            referring/exit pages, and the number of clicks. This data is used to
            analyze trends, administer the site, track user’s movement around
            the site, and gather demographic information.
          </p>
          <br />
          <p>5. Name and address provided when ordering our merchandise.</p>
          <br />
          <p>
            The Personal Data that you are asked to provide, and the reasons why
            you are asked to provide it, will be made clear to you at the point
            we ask you to provide your Personal Data.
          </p>
          <br />
          <b>
            We do not, under any circumstances, ask you to share your private
            keys or wallet seed. Any request for such information should be
            considered fraudulent.
          </b>
          <br />
          <br />
          <b>3. Usage of Personal Data</b>
          <br />
          <p>We use your Personal Data for the following purposes:</p>
          <br />
          <p>
            1. For our internal and operational purposes, such as ensuring
            security, identifying irregular website behavior, and preventing
            fraudulent activity.
          </p>
          <p>2. For assessing and improving the performance of the Software.</p>
          <p>3. For analyzing our user behaviors to improve the Software.</p>
          <p>4. For delivering our merchandise products to users.</p>
          <p>
            5. For sending you marketing, advertising, and informational emails.
          </p>
          <p>6. For preventing fraud.</p>
          <br />
          <p>In addition, we may use your Personal Data on the basis of:</p>
          <br />
          <p>1. Contract performance or necessity to enter into a contract.</p>
          <p>
            2. Our, or our processors’, legitimate interests to protect the
            Software, prevent malicious and harmful activities, maintain our
            technical systems healthy and secure, improve services and products
            by using aggregate statistics.
          </p>
          <p>
            3. To comply with legal requests of authorities, provide information
            upon court orders and judgments, or if we believe disclosure is
            necessary to comply with the law.
          </p>
          <p>4. On the basis of your consent.</p>
          <br />
          <b>4. Disclosure of Data</b>
          <br />
          <p>
            We may disclose any Personal Data about you in connection with a
            merger, division, restructuring, or other company change, or to our
            subsidiaries or affiliates if necessary for operational purposes.
          </p>
          <br />
          <b>5. Data Retention</b>
          <br />
          <p>
            We retain Personal Data only for as long as necessary for the
            purposes set out in this Privacy Policy. Aggregated data, which
            cannot directly identify an individual, is maintained until it is no
            longer commercially necessary or until you object or withdraw
            consent.
          </p>
          <br />
          <b>6. Cookies and Automatically Collected Data</b>
          <br />
          <p>
            As you navigate through and interact with our Software, we may use
            cookies and web beacons, subject to your consent. Cookies are small
            files placed on the hard drive or browser of your computer or mobile
            device, and web beacons are small electronic files located on pages
            of the Software. These tools help us to collect certain information
            about your device, browsing actions, and patterns.
          </p>
          <br />
          <p>
            Data collected from cookies and web beacons may include your web
            browser details (such as browser type and language) and details
            about your visits to the Software and website, including traffic
            data, location data, logs, page views, length of visit, and
            navigation paths. We also gather data about your device and internet
            connection, including your IP address and your interactions with the
            Software. This information helps us to improve the Software and
            enhance your user experience.
          </p>
          <br />
          <p>
            The information we collect automatically may also include
            statistical and performance information arising from your use of the
            Software. We will use this type of data in an aggregated and
            pseudonymized manner.
          </p>
          <br />
          <p>
            You have the option to disable cookies through your individual
            browser settings. For more detailed information about cookie
            management with specific web browsers, you can visit the browsers'
            respective websites:
          </p>
          <br />
          <p>
            {`\u25CF`} For{" "}
            <a href="https://support.google.com/chrome/answer/95647?hl=en">
              Google Chrome browser
            </a>
          </p>
          <p>
            {`\u25CF`} For{" "}
            <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">
              Firefox browser
            </a>
          </p>
          <p>
            {`\u25CF`} For{" "}
            <a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac">
              Safari browser
            </a>
          </p>
          <p>
            {`\u25CF`} For{" "}
            <a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
              Internet Explorer browser
            </a>
          </p>
          <p>
            By modifying your browser preferences, you have the choice to accept
            all cookies, to be notified when a cookie is set, or to reject all
            cookies. If you choose to reject all cookies, you may be unable to
            use those Software features that require registration in order to
            participate or will have to re-register each time you visit the
            Software.
          </p>
          <br />
          <b>7. Your Rights Under the GDPR</b>
          <br />
          <p>
            As per the General Data Protection Regulation (GDPR), if you are a
            resident of the European Economic Area (EEA), you possess several
            privacy rights in relation to the use, storage, and processing of
            your Personal Data. Here is a detailed list of your rights:
          </p>
          <br />
          <p>
            <b>{`\u25CF`} Right to be Informed:</b> We've designed this Privacy
            Policy to keep you informed about how we handle your Personal Data.
            You can request information at any time about your Personal Data
            that we store. This information includes the categories of data we
            process, the purposes for which we process them, the origin of the
            data if we didn't acquire them directly from you, and, if
            applicable, the recipients to whom we have sent your data.
          </p>
          <br />
          <p>
            <b>{`\u25CF`} Right of Access:</b> You have the right to know
            whether we process your Personal Data and to request a copy of the
            data we store about you.
          </p>
          <br />
          <p>
            <b>{`\u25CF`} Right to Rectification:</b> If the Personal Data we
            hold about you is inaccurate or incomplete, you have the right to
            have it corrected or completed.
          </p>
          <br />
          <p>
            <b>{`\u25CF`} Right to Erasure (‘Right to be Forgotten’):</b> You
            can request the erasure of your Personal Data from our systems. We
            will comply unless there's a legitimate, legal, or statutory reason
            not to. Note that while we can delete Personal Data subjected to
            ongoing processing activities, we may retain certain data in our
            backup and archive systems to fulfill statutory and other
            requirements.
          </p>
          <br />
          <p>
            <b>{`\u25CF`} Right to Restriction of Processing:</b> Under certain
            conditions, you can request us to restrict or 'block' the processing
            of your Personal Data.
          </p>
          <br />
          <p>
            <b>{`\u25CF`} Right to Data Portability:</b> You have the right to
            receive your Personal Data in a structured, commonly used, and
            machine-readable format, and have it transferred to another
            organization. Please contact{" "}
            <a href="mailto:info@thebotfather.io">info@thebotfather.io</a> to
            find out if we currently support the provision of a portable file
            containing the Personal Data we process about you.
          </p>
          <br />
          <p>
            <b>{`\u25CF`} Right to Object:</b> You can object to the processing
            of your Personal Data by contacting{" "}
            <a href="mailto:info@thebotfather.io">info@thebotfather.io</a> at
            any time, particularly in cases where the processing is based on our
            legitimate interest, where we carry out profiling, or use machine
            learning or automated decision-making algorithms.
          </p>
          <br />
          <p>
            <b>{`\u25CF`} Right to Withdraw Consent:</b> If you have previously
            given consent to the processing of your Personal Data for certain
            purposes, you have the right to withdraw your consent at any time.
          </p>
          <br />
          <p>
            <b>{`\u25CF`} Right to Lodge a Complaint:</b> We take your rights
            very seriously. However, if you believe we haven't adequately
            addressed your complaints, you have the right to lodge a complaint
            with the data protection authorities responsible. You can direct
            your complaints to the EEA supervisory authority in your country of
            residence.
          </p>
          <br />
          <p>
            For any questions about exercising any of the above rights or to
            initiate a request, please email{" "}
            <a href="mailto:info@thebotfather.io">info@thebotfather.io</a>. For
            more information about the GDPR and your rights, you can refer to
            the website of the Information Commissioner's Office or a similar
            authority in your country of residence.
          </p>
          <br />
          <b>8. Privacy of Children</b>
          <p>
            We do not intentionally collect Personal Data from individuals under
            the age of 18, in compliance with the Children's Online Privacy
            Protection Act (COPPA). Our Software is not designed to attract the
            attention of persons under the age of 18. If you believe that we
            might have any data from or about a child under 18, please contact
            us at <a href="mailto:info@thebotfather.io">info@thebotfather.io</a>
            , and we will endeavor to delete that data from our systems.
          </p>
          <br />
          <b>9. Transfer of Personal Data</b>
          <p>
            Transfers of Personal Data to third countries are conducted in
            accordance with the requirements set forth in Chapter V of the GDPR,
            providing that such transfers shall only take place if certain
            conditions are met, including the provision of adequate safeguards,
            such as Standard Contractual Clauses adopted by the supervisory
            authority and approved by the European Commission. You may obtain a
            copy of these safeguards by submitting a prior written request to
            <a href="mailto:info@thebotfather.io">info@thebotfather.io</a>. We
            may guide you on further steps required to obtain such a copy,
            including any obligation on your part to agree to confidentiality
            commitments.
          </p>
          <br />
          <p>
            Keep in mind that our Software relies on public blockchains intended
            to immutably record transactions across broad networks of computer
            systems. These blockchains can potentially be subjected to forensic
            analysis which could lead to deanonymization and unintentional
            revelation of Personal Data, particularly when blockchain data is
            combined with other data. Given the decentralized nature of these
            networks, which we do not control or operate, we are unable to
            erase, modify, or alter Personal Data from such networks.
          </p>
          <br />
          <b>10. Data Integrity & Security of Processing</b>
          <p>
            We employ stringent security measures to ensure the integrity of
            Personal Data and protect it from loss, misuse, or unauthorized
            access, in compliance with Article 32 of the GDPR. These measures
            include encryption, digital and physical access controls,
            non-disclosure agreements, and other technical and organizational
            measures.
          </p>
          <br />
          <p>
            However, please note that no method of electronic transmission or
            storage is 100% secure. While we strive to protect your Personal
            Data, we cannot guarantee its absolute security. We advise you to
            use our services in a secure environment.
          </p>
          <br />
          <b>11. Supervisory Authority Oversight</b>
          <p>
            As per Article 77 of the GDPR, if you are a data subject whose
            Personal Data we process, you may have the right to lodge a
            complaint with a data protection regulator in one or more of the EU
            member states. A list of data protection authorities in Europe can
            be found{" "}
            <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en">
              here
            </a>
            .
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};
