import { useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GlobalContext } from "../../context/GlobalContext";
import freeplan from "../../assets/images/free-trial.png";

export const PlanData = ({ data }) => {
  const globalContext = useContext(GlobalContext);
  const navigate = useNavigate();

  const subsciptionPurchase = (plan) => {
    if (globalContext.loggedIn) {
      if (
        globalContext.userInfo?._subscription &&
        plan._id !== globalContext.userInfo?._subscription?._id
      ) {
        Swal.fire({
          title: "Warning!",
          text: "Are you sure you want to upgrade the plan? Your active plan will be stopped.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Proceed",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/subscriptionplan?id=${plan._id}`);
          }
        });
      } else {
        navigate(`/subscriptionplan?id=${plan._id}`);
      }
    } else {
      navigate("/login", {
        state: {
          plan: plan._id,
        },
      });
    }
  };

  return (
    <Row>
      {data.map(
        (d, i) =>
          (d.planStatus !== "DISABLED" ||
            d._id === globalContext.userInfo?._id) && (
            <Col className="mb-5 trial-plan" md={3} key={i}>
              {d.hasTrial === "1" && (
                <img src={freeplan} alt="freeplan" className="free-trial" />
              )}
              <div
                className={`plans-content-area ${
                  globalContext.userInfo?.planPrice &&
                  globalContext.userInfo?.planPrice > d.planPrice &&
                  d._id !== globalContext.userInfo?._subscription?._id
                    ? "disable-plan"
                    : ""
                }`}
                onClick={() => {
                  if (
                    !globalContext.userInfo?.planPrice ||
                    globalContext.userInfo?.planPrice <= d.planPrice ||
                    d._id === globalContext.userInfo?._subscription?._id
                  )
                    subsciptionPurchase(d);
                }}
              >
                <h6>{d.planName}</h6>
                <h2>
                  ${d.planPrice}/<span>{d.durationUnit}</span>
                </h2>
                <hr></hr>
                <ul>
                  {d.benifits.map((b, j) => (
                    <li key={j}>
                      <img
                        src={require("../../assets/images/tick.png")}
                        alt="img"
                      />{" "}
                      {b}
                    </li>
                  ))}
                </ul>
                <Button
                  type="button"
                  variant="unset"
                  disabled={
                    globalContext.userInfo?.planPrice &&
                    globalContext.userInfo?.planPrice > d.planPrice &&
                    d._id !== globalContext.userInfo?._subscription?._id
                      ? true
                      : false
                  }
                >
                  {globalContext.userInfo?._subscription
                    ? d._id === globalContext.userInfo?._subscription?._id
                      ? "Active Plan"
                      : globalContext.userInfo?.planPrice > d.planPrice
                      ? "Disabled"
                      : "Upgrade Plan"
                    : "Choose Plan"}
                </Button>
              </div>
            </Col>
          )
      )}
    </Row>
  );
};
