import Modal from "react-bootstrap/Modal";

export const Terms = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-view"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          TERMS OF USE
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-3">
          <b>ARTICAL 1: General</b>
          <p>
            1.1. These Terms of Use and any terms and conditions incorporated
            herein by reference (collectively, the “<b>Terms</b>”) govern your
            access to and use of the Software. You must read the Terms
            thoroughly and carefully. To make these Terms easier to read:{" "}
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;I. Botfather LLC is referred to as
            “Software”, “interface”, “we”, “us” or “our”.
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;II. “You”, “your” and “user(s)” refers to
            anybody who accesses or uses, in any way, the Software. If you are
            accessing or using the Software on behalf of a company (such as your
            employer) or other legal entity, you represent and warrant that you
            have the authority to bind that entity to these Terms and, in that
            case, “you”, “your” or “user(s)” will refer to that entity.
          </p>
          <p>
            1.2. By accessing, browsing or otherwise using the Software, or by
            acknowledging agreement to the Terms on the Software, you agree that
            you have read, understood and accepted all of the Terms and our
            Privacy Policy (the “<b>Privacy Policy</b>”), which is incorporated
            by reference into the Terms.
          </p>
          <p>
            1.3. <b>IMPORTANT NOTE REGARDING ARBITRATION:</b> WHEN YOU AGREE TO
            THESE TERMS BY USING OR ACCESSING THE SOFTWARE PROVIDED BY BOTFATHER
            LLC, YOU ARE CONSENTING TO RESOLVE ANY DISPUTE THAT MAY ARISE
            BETWEEN YOU AND BOTFATHER LLC THROUGH BINDING, INDIVIDUAL
            ARBITRATION AS PROVIDED BY THE FEDERAL ARBITRATION ACT (FAA, 9
            U.S.C. § 1-16) RATHER THAN THROUGH COURT LITIGATION. BY AGREEING TO
            THESE TERMS, YOU EXPRESSLY WAIVE YOUR RIGHT TO PARTICIPATE IN ANY
            CLASS ACTION LAWSUIT AGAINST BOTFATHER LLC. THIS CLASS ACTION WAIVER
            MEANS YOU CANNOT JOIN A CLASS ACTION AS A PLAINTIFF OR A CLASS
            MEMBER OR, PARTICIPATE IN ANY CONSOLIDATED OR REPRESENTATIVE
            PROCEEDING (EXISTING OR FUTURE) BROUGHT AGAINST BOTFATHER LLC. YOU
            ARE ALSO WAIVING YOUR RIGHT TO A TRIAL BY JURY. INSTEAD, A NEUTRAL
            ARBITRATOR (NOT A JUDGE OR JURY) WILL DECIDE ANY DISPUTE THAT MAY
            ARISE BETWEEN YOU AND BOTFATHER LLC. THE ARBITRATOR'S DECISION WILL
            BE FINAL AND BINDING, AND THE ENFORCEMENT OF THE ARBITRATION AWARD
            WILL BE GOVERNED BY THE FAA. IF YOU DO NOT AGREE TO THESE TERMS, YOU
            SHOULD NOT USE OR ACCESS THE SOFTWARE. BY CONTINUING TO USE OR
            ACCESS THE SOFTWARE, YOU SIGNIFY YOUR AGREEMENT TO THESE ARBITRATION
            PROVISIONS STATED HERE AND IN ARTCILE 10, SECTIONS 10.1 THROUGH
            10.13.
          </p>
          <br />
          <b>ARTICAL 2: Eligibility</b>
          <b>2.1. Access Criteria:</b>
          <p>
            &nbsp;&nbsp;I. Access To access or use the Software, you must be of
            sufficient legal age and capacity to form a legally binding contract
            with us under any applicable law. Accordingly, by accessing or using
            the Software, you represent and warrant that:
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;a. You are at least eighteen (18) years old,
            the age of majority in most jurisdictions, which is the legally
            required age to enter into agreements such as this Agreement.
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;b. You have the full right, power, and
            authority to enter into and comply with the terms and conditions of
            this Agreement on behalf of yourself and any company or legal entity
            for which you may access or use the Software. This includes, but is
            not limited to, ensuring that your use of the Software does not
            violate any applicable laws or regulations in your jurisdiction,
            including but not limited to copyright laws, sanction laws, and
            export control laws.{" "}
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;c. You are not a citizen, resident, or
            member of any jurisdiction or group that is subject to economic
            sanctions by the United States, as specified and updated from time
            to time by the Office of Foreign Assets Control (OFAC) of the U.S.
            Department of the Treasury, or where your use of the Software would
            be illegal or otherwise violate any applicable law. You further
            represent that you have not been designated as a "Specially
            Designated National" by OFAC or been placed on any other sanctions
            list.{" "}
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;d. You will not access or use the Software
            to conduct, promote, or otherwise facilitate any illegal activity.
            You further represent that your access and use of the Software will
            fully comply with all applicable laws and regulations. Any use or
            attempted use of the Software (i) in violation of this Agreement,
            (ii) in violation of any law, regulation, or rights of any third
            party, or (iii) for any purpose other than its intended purposes, is
            strictly prohibited.
          </p>
          <br />
          <p>
            &nbsp;&nbsp;Our Software is <b>NOT</b> offered to persons or
            entities who reside in, are citizens of, are incorporated in, any
            Prohibited Localities, namely Restricted Persons, as defined below.
            We do not make exceptions. If you are a Restricted Person, then do
            not attempt to access or use the Software. Use of a virtual private
            network (e.g., a VPN) or other means by Restricted Persons to access
            or use the Software is{" "}
            <b>
              prohibited. You may not use the Software if you are otherwise
              barred from using the Software under applicable law.
            </b>
          </p>
          <b>2.2. Compliance and Legality:</b>
          <p>
            &nbsp;&nbsp;I. You, the user, bear the sole responsibility for
            ensuring adherence to all laws, regulations, and rules applicable to
            your use or access of the Software. This includes, but is not
            limited to, local, state, federal, and international laws and
            regulations. Your use of the Software is expressly prohibited if it
            would infringe or facilitate the infringement of any applicable laws
            or regulations, or if it would contribute to or facilitate any
            illegal activity, whether directly or indirectly.
          </p>
          <p>
            &nbsp;&nbsp;By using or accessing the Software, you represent and
            warrant to us that:
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;You are not subject to the "
            <b>Sanction Lists</b>" as defined below.
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;You are not a "<b>Restricted Person</b>" as
            defined in this Agreement or by any relevant law or regulation.
          </p>
          <p>
            &nbsp;&nbsp;II. The term "Sanction Lists" refers to any
            economic/trade embargo lists and/or lists of specially designated
            persons or blocked persons published by international organizations,
            as well as any state and governmental authorities of any
            jurisdiction. This includes, but is not limited to, the lists of the
            United Nations, the European Union and its Member States, the United
            States, and the United Kingdom.
          </p>
          <p>
            &nbsp;&nbsp;III. BOTFATHER LLC provides no assurances or warranties
            that the information, products, or services facilitated through our
            Software are suitable or pertinent for access or utilization in
            jurisdictions outside the one expressly stated in this agreement.
            You are strictly prohibited from accessing or using our Software in
            any jurisdiction or country where such access or use would
            contravene the law or regulation of that jurisdiction, or if it
            would expose BOTFATHER LLC to the jurisdiction's laws, or any
            obligatory registration requirement within such jurisdiction.
          </p>
          <p>
            &nbsp;&nbsp;IV. If any violation of this provision by you, the user,
            leads to operational disruption, legal exposure, or financial loss
            to BOTFATHER LLC, you agree to accept responsibility for such
            breach. You also agree to indemnify BOTFATHER LLC for any and all
            resultant damages, losses, or expenses, including reasonable legal
            fees, to the extent permitted by applicable law. Please note that
            continued use of the Software signifies your understanding and
            acceptance of these terms. If you do not agree with these terms, you
            are required to cease use of the Software immediately. Your
            responsibility to indemnify BOTFATHER LLC shall survive any
            termination or expiration of this Agreement.
          </p>
          <p>
            &nbsp;&nbsp;V. BOTFATHER LLC reserves the right, at any time and at
            its sole and absolute discretion, to limit the availability of our
            Software to any person, geographic area, or jurisdiction. We may
            exercise this right on a case-by-case basis. We further reserve the
            right to suspend your access to the Software if we believe, in our
            sole discretion, that you have violated any applicable laws or
            regulations, or have otherwise used the Software in a manner that
            may cause harm to us or any third party.
          </p>
          <br />
          <b>2.3. Prohibited Jurisdictions</b>
          <p>
            &nbsp;&nbsp;I. The Software is expressly prohibited from interacting
            with digital wallets located in, established in, or held by
            residents of certain countries or regions due to their presence on
            international sanction lists. These jurisdictions currently include,
            but are not limited to, Myanmar (Burma), Cote D'Ivoire (Ivory
            Coast), Cuba, Crimea and Sevastopol, Democratic Republic of Congo,
            Iran, Iraq, Libya, Mali, Nicaragua, Democratic People’s Republic of
            Korea (North Korea), Somalia, Sudan, Syria, Yemen, Zimbabwe or any
            other state, country or region that is included in the Sanction
            Lists.
          </p>
          <p>
            &nbsp;&nbsp;II. You, as the user, are expressly forbidden from using
            the Software if you are located in, established in, or a resident of
            any of these prohibited jurisdictions. This prohibition applies
            regardless of your physical location at the time of accessing the
            Software.{" "}
          </p>
          <p>
            &nbsp;&nbsp;III. Please note, BOTFATHER LLC reserves the right to
            update this list of prohibited jurisdictions in accordance with
            changes to international sanctions and regulations, or at our own
            discretion, and without prior notice to you. It is your
            responsibility to review this Agreement regularly for any such
            changes.
          </p>
          <br />
          <b>2.4. Restricted Persons:</b>
          <p>
            &nbsp;&nbsp;I. BOTFATHER LLC expressly prohibits interaction with
            digital wallets associated with individuals or entities identified
            as "Restricted Persons." For the purposes of these Terms,
            "Restricted Persons" include, but are not limited to:
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;a. Any individuals or entities specifically
            designated or identified on the Sanction Lists issued by
            international organizations or any state or governmental authorities
            of any jurisdiction. This includes individuals or entities that have
            been classified as being affiliated or associated with those
            specifically included in the Sanction Lists.
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;b. Any individuals or entities that reside
            in, are citizens of, are incorporated in, or have a registered
            office in the Prohibited Localities as specified in this Agreement.
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;c. Please note that our definition of
            "Restricted Persons" may be expanded or modified to comply with
            changes in applicable laws, regulations, or sanctions, or based on
            our sole discretion.
          </p>
          <p>
            &nbsp;&nbsp;II. It is strictly forbidden to access or use our
            Software if you are a Restricted Person. If we determine that you
            are a Restricted Person, we reserve the right to immediately
            terminate your access to the Software and take any other necessary
            measures to comply with applicable laws and regulations.
          </p>
          <br />
          <b>2.5. Non-Circumvention:</b>
          <p>
            &nbsp;&nbsp;I. You the user, are strictly prohibited from employing
            any software, networking techniques, or other technological
            measures, including but not limited to the use of a Virtual Private
            Network (VPN), proxy servers, or any other method intended to
            falsify, mask, or modify your internet protocol (IP) address, or to
            otherwise circumvent or attempt to circumvent this prohibition. Any
            attempt to do so is considered a breach of this Agreement and may
            result in immediate termination of your access to the Software and
            could subject you to legal penalties.{" "}
          </p>
          <p>
            &nbsp;&nbsp;II. Continued use of our Software signifies your
            understanding and acceptance of these restrictions and terms. Any
            attempt to circumvent these restrictions is considered a serious
            breach of these Terms and may result in legal consequences.
          </p>
          <br />
          <b>Artcile 3: Compliance Obligations </b>
          <p>
            3.1. Please be aware that the availability and suitability of the
            Software may vary based on differing legal and regulatory
            requirements across jurisdictions. By accessing or using the
            Software, you hereby acknowledge and agree that you bear the sole
            and absolute responsibility for ensuring compliance with all local,
            state, federal, and international laws, regulations, and ordinances
            that may apply to your use of the Software. This includes, but is
            not limited to, laws and regulations relating to data privacy,
            international communications, and the transmission of technical or
            personal data.{" "}
          </p>
          <p>
            3.2. In addition, you explicitly agree and acknowledge that
            BOTFATHER LLC bears no obligation to advise or inform you of
            potential liabilities or violations of laws or regulations that may
            arise in connection with your access and use of the Software. We do
            not warrant or represent that your use of the Software will comply
            with laws applicable to you, and we disclaim all liability in this
            regard.
          </p>
          <p>
            3.3. Please note that BOTFATHER LLC is not liable in any way for any
            failure by you, the user, to comply with any applicable laws or
            regulations. It is your sole responsibility to interpret and adhere
            to all applicable laws in your jurisdiction.{" "}
          </p>
          <p>
            3.4. By continuing to use or access the Software, you are
            representing that your activities are lawful in the jurisdiction
            where you access or use the Software, and that you will cease all
            use of and access to the Software in any jurisdiction where your use
            or access, or the availability of the Software, would be contrary to
            any applicable law.
          </p>
          <p>
            3.5. We encourage you to seek legal advice if you are unsure of the
            laws and regulations in your jurisdiction which relate to your use
            of our Software. Your continued use of the Software signifies your
            understanding and acceptance of this obligation. Non-compliance with
            this section of the Terms can result in immediate termination of
            your access to the Software and other legal penalties.
          </p>
          <br />
          <b>Article 4: Right to Terminate Access & No Refund policy</b>
          <p>
            4.1. BOTFATHER LLC expressly reserves the right to disable, suspend,
            or terminate your access to the Software at any time, without prior
            notice, in the event of any violation or breach of these Terms. This
            includes, but is not limited to, situations where we, at our sole
            discretion, determine that you have failed to meet or maintain the
            eligibility requirements set forth in these Terms. Furthermore, we
            reserve the unilateral right to limit, restrict, or prohibit access
            to the Software for any individual, entity, within any specific
            geographic area, or legal jurisdiction, at any time and for any
            reason, at our sole discretion.
          </p>
          <p>
            4.2. In the event of such suspension, termination, or access
            limitation due to a violation or breach of these Terms, any and all
            payments, fees, or other amounts paid by you in relation to the
            Software are non-refundable. This no-refund policy applies to the
            fullest extent permissible under applicable law and commences at the
            time such payment, fee, or other amount is paid.
          </p>
          <p>
            4.3. Please be advised that BOTFATHER LLC shall not be liable to you
            or any third party for any losses, damages, costs, expenses, or
            liabilities that you may incur as a result of or in connection with
            the Software being inaccessible to you at any time or for any
            reason. This includes but is not limited to losses arising from your
            inability to access the Software, loss of data, the appropriateness
            of your using the Software, and any unauthorized access to or
            alteration of your transmissions or data in connection with the
            Software. This limitation of liability applies to all causes of
            action, in the aggregate including, but not limited to, breach of
            contract, breach of warranty, negligence, strict liability,
            misrepresentations, and any other tort.
          </p>
          <p>
            4.4. Your continued use of the Software signifies your
            understanding, consent, and acceptance of these conditions and
            limitations.
          </p>
          <br />
          <b>Article 5: Your Use of Software</b>
          <p>
            5.1. By accessing or utilizing our Software, you unequivocally
            acknowledge and warrant your understanding of the inherent risks
            associated with virtual currencies and the underlying technologies,
            including but not limited to, cryptographic algorithms, blockchain
            technology, and decentralized systems. You expressly agree that
            Botfather LLC bears no responsibility or liability for any losses,
            damages, or adverse consequences arising from these risks. You
            acknowledge and consent that the Software primarily interacts with
            decentralized networks and blockchain systems. As such, Botfather
            LLC neither possesses any control over these blockchain networks or
            virtual currencies, nor can guarantee the confirmation of your
            interactions on the relevant blockchain. We are unable to effectuate
            any requests for cancellation or modifications of your interactions
            and are not in control of your interactions.
          </p>
          <p>
            5.2. Without diminishing the general scope of the foregoing, you
            explicitly understand and hereby represent your acknowledgment of
            the following:
          </p>
          <p>
            &nbsp;&nbsp; I. <b>Accuracy of Data:</b> Although it is intended to
            provide accurate and timely information on the Software the Software
            or relevant tools may not always be entirely accurate, complete or
            current and may also include technical inaccuracies or typographical
            errors. Accordingly, you should verify all information before
            relying on it, and all decisions based on information contained on
            the Software or relevant tools are your sole responsibility.{" "}
          </p>
          <p>
            &nbsp;&nbsp; II. <b>Risk Acknowledgment:</b> The information related
            to pricing data provided through the Software does not constitute an
            offer, a solicitation of an offer, or any advice or recommendation
            to enter into a transaction usingthe Software.
          </p>
          <p>
            &nbsp;&nbsp; III. <b>Independent Operation:</b> The Software does
            not act as an agent for any user, nor does it own or control any of
            the underlying programs facilitating blockchain networks. We are
            therefore not responsible for their operation or performance.
          </p>
          <p>
            &nbsp;&nbsp; IV. <b>Autonomous Utilization:</b> The Software
            functions purely as a technical facilitator and does not serve as an
            agent or representative for any user. Furthermore, the Software
            neither possesses nor exercises control over any of the foundational
            programs enabling blockchain networks and, therefore, bears no
            liability for their functioning or performance.
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; a. The User acknowledges and accepts that
            they direct the Software's actions concerning the execution of
            transactions such as "spend", "trade", or "swap" of cryptocurrency
            tokens from their integrated wallets. BOTFATHER LLC holds no control
            over the user's configuration of the Software to facilitate a
            transaction or the parameters at which the transaction will occur.
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; b. Consequently, the user willingly
            relinquishes any potential claim against BOTFATHER LLC related to
            these transactions, fully recognizing and accepting that they retain
            exclusive control over such operations.
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; c. This clause explicitly highlights the
            user's responsibility and ownership of their actions and
            transactions made via the Software, thereby excluding BOTFATHER LLC
            from any potential liabilities resulting from such activities.
          </p>
          <p>
            &nbsp;&nbsp; V. <b>Tax Responsibilities:</b> As the user, you solely
            bear the responsibility of reporting and remitting any taxes arising
            from your use of the Software.
          </p>
          <p>
            &nbsp;&nbsp; VI. <b>Information Accuracy:</b> While the Software
            aims to provide accurate and timely information, it may contain
            inaccuracies, incompleteness, or typographical errors. You should
            verify all information before relying on it, and all decisions based
            on information contained on the Software are your sole
            responsibility.
          </p>
          <p>
            &nbsp;&nbsp; VII. <b>Prohibited Activities:</b> By accessing or
            utilizing the Software, you pledge not to engage in, or attempt to
            engage in, any prohibited activities. This includes any action that:
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; 1. Violates the Terms and Conditions of the
            Software;
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; 2. Infringes on any intellectual property
            rights; Infringes on or violates any copyright, trademark, service
            mark, patent, right of publicity, right of privacy, or other
            proprietary or intellectual property rights under the law;
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; 3. Interferes with the integrity or
            security of any IT system; Activity that seeks to interfere with or
            compromise the integrity, security, or proper functioning of any
            computer, server, network, personal device, or other information
            technology system, including (but not limited to) the deployment of
            viruses and denial of service attacks or activities that seek to
            interfere with or compromise the integrity, security, or proper
            functioning of any computer, server, network, personal device, or
            other information technology system;
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; 4. Attempts to unlawfully obtain user data
            or other security information; This includes attempts, in any
            manner, to obtain the private key, password, account, or other
            security information from any other user, including such information
            about the digital wallet;
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; 5. Involves reverse-engineering or
            otherwise attempts to gain unauthorized access to the Software such
            as attempts to Decompile, reverse engineer, or otherwise attempt to
            obtain the source code or underlying ideas or information of or
            relating to the Software;
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; 6. Defrauds or seeks to defraud us or any
            other entity; including, but not limited to, providing any false,
            inaccurate, or misleading information in order to unlawfully obtain
            the property of another;
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; 7. Violates any law concerning market
            integrity; Such as Market Manipulations or activities including, but
            not limited to, the manipulative tactics commonly known as spoofing
            and wash trading;
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; 8. Disguises or interferes in any way with
            the IP address of the computer you are using to access or use the
            Software or that otherwise prevents us from correctly identifying
            the IP address of the computer you are using to access the Software;{" "}
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; 9. Involves funds from unlawful activities
            whether direct or indirect.
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; 10. Contributes to or facilitates any of
            the aforementioned activities;
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; 11. Violates any applicable laws, rules, or
            regulations of the United States or another relevant jurisdiction.
          </p>
          <p>
            &nbsp;&nbsp; II. <b>Liability:</b> We only provide you with access
            to the Software; we neither control nor encourage your interactions
            with the blockchain. Any such interaction remains your sole
            responsibility. We only provide you with the relevant Software and
            interface and neither has control over your interactions with the
            blockchain nor encourages you to perform any. Any interaction
            performed by you via the Software or Interface remains your sole
            responsibility.{" "}
          </p>
          <p>
            &nbsp;&nbsp; III. <b>Informational Purpose:</b> All information
            provided in connection with your use of the Software is for
            informational purposes only and should not be construed as
            professional advice. Before making any financial, legal, or other
            decisions involving the Software, seek independent professional
            advice. You should not take, or refrain from taking, any action
            based on any information contained in the Software or any other
            information that we make available at any time, including, without
            limitation, blog posts, articles, links to third-party content, news
            feeds, tutorials, tweets and videos. Before you make any financial,
            legal, or other decisions involving the Software or Cryptocurrency
            for that matter, you should seek independent professional advice
            from an independent professional who is licensed and qualified in
            the area for which such advice would be appropriate.{" "}
          </p>
          <p>
            &nbsp;&nbsp; IV. <b>Non-Fiduciary Relationship:</b> The Terms do not
            impose any fiduciary duties on us. You acknowledge and agree that we
            owe no fiduciary duties or liabilities to you or any other party. To
            the extent any such duties or liabilities may exist, they are
            irrevocably disclaimed, waived, and eliminated. The only duties and
            obligations we owe you are those expressly stated in the Terms.
          </p>
          <p>
            5.3. Your continued use of the Software signifies your
            understanding, consent, and acceptance of these conditions and
            limitations.
          </p>
          <b>Artcile 6: Disclaimers</b>
          <p>
            6.1. <b>Decentralized Environment:</b> You acknowledge and agree
            that the GitHub repository, the associated decentralized and
            autonomous protocols, and all associated decentralized networks
            (collectively, the "Decentralized Systems") are not owned, operated,
            or controlled by Botfather LLC or our Software. The Decentralized
            Systems are separate and independent from Botfather LLC and our
            Software, and your interactions with them are at your sole risk and
            discretion.
          </p>
          <p>
            6.2. <b>No Access to Private Keys:</b> Botfather LLC does not, at
            any time, have access to your private keys. We cannot initiate any
            interactions with your virtual currency, access your virtual
            currency, or control your digital wallet in any manner. We disclaim
            all liability for any activities, transactions, or operations that
            you undertake using your wallet or through the Software.
          </p>
          <p>
            6.3. <b>Reliability of Information:</b> Botfather LLC does not
            represent, warrant, or guarantee the accuracy, reliability,
            timeliness, completeness, or appropriateness of any information
            available through the Software. This includes, but is not limited
            to, "APRs," prices, liquidity data, staking data, and any other
            information that is provided by third parties or calculated for
            informational purposes. All such information is provided on an "as
            is" and "as available" basis, and you rely on it strictly at your
            own risk.
          </p>
          <p>
            6.4. <b>Third-Party Scripts:</b> Any third-party scripts,
            indicators, ideas, and other content that you may access or use in
            connection with the Software are not controlled or vetted by
            Botfather LLC. You acknowledge and agree that your use of such
            third-party materials is at your sole risk, and Botfather LLC
            disclaims all liability for any harm, loss, or damage that may arise
            from your use of such materials.
          </p>
          <p>
            6.5. <b>No Responsibility for Your Actions:</b> We are not
            responsible for any of your actions or omissions, or for the actions
            or omissions of any third party, related to your use of the Software
            or the Decentralized Systems. You bear sole responsibility for all
            decisions and actions taken or not taken involving your use of the
            Software or the Decentralized Systems, and for complying with all
            laws, rules, and regulations applicable to such decisions and
            actions.
          </p>
          <p>
            6.6. <b>Informational Purposes Only:</b> All information provided by
            the Software is purely for informational purposes and does not
            constitute professional advice of any sort. You understand and agree
            that any action, inaction, decision, or strategy you undertake based
            on this information is strictly at your own risk and discretion.
            Before you engage in any financial, legal, or other significant
            actions involving the Software, we strongly recommend that you seek
            independent professional advice from an individual who is licensed
            and qualified in the relevant area.
          </p>
          <p>
            6.7. <b>Risk Acknowledgement:</b> By accessing and using the
            Software, you acknowledge your understanding of (a) the inherent
            risks associated with the use of the Software as it facilitates
            access to the Protocol, and (b) the inherent risks associated with
            cryptographic and blockchain-based systems. You affirm that you
            possess a working knowledge of blockchain-based digital assets,
            including, but not limited to, the ERC-20 token standard available
            on the Ethereum blockchain, all other available layer 2 solutions
            for Ethereum, and other blockchains.
          </p>
          <p>
            6.8. <b>Assumption of Risk:</b> You expressly acknowledge and agree
            that your use of the Software is at your sole risk. We provide the
            Software and its associated information on an "as is" and "as
            available" basis, without any express or implied warranties of any
            kind. Consequently, you agree to assume full responsibility for all
            the risks involved in accessing and using the Software to interact
            with the Protocol.
          </p>
          <p>
            6.9. <b>Disclaimer of Warranties:</b> Botfather LLC makes no
            representations or warranties, express or implied, statutory or
            otherwise, concerning the Software, the underlying code (be it
            proprietary or open-source), or any related information.
            Specifically, Botfather LLC expressly disclaims any and all
            warranties of title, non-infringement, merchantability, security,
            suitability, fitness for a particular purpose, or the absence of any
            defects, whether latent or patent. We do not guarantee that the
            Software or its related information is accurate, complete, reliable,
            current, error-free, or secure.
          </p>
          <p>
            6.10. <b>No Guarantee of Third-Party Representations:</b> Botfather
            LLC does not endorse, guarantee, or assume responsibility for any
            advertisements, offers, or statements made by third parties
            regarding the Software. You understand and agree that any reliance
            on such third-party materials is strictly at your own risk.
          </p>
          <p>
            6.11. <b>No Creation of Warranties:</b> You acknowledge that no
            advice, information, or statement provided by us, whether oral or
            written, will create any warranty not expressly stated in these
            Terms. Any failure by Botfather LLC to exercise or enforce any right
            or provision of these Terms will not constitute a waiver of such
            right or provision.
          </p>
          <p>
            6.12. <b>Responsibility for Private Keys and Virtual Currency:</b>{" "}
            You acknowledge and agree that the Software does not and will not
            hold, manage, transfer, or safeguard your private keys or any
            associated virtual currencies. You understand that if you lose,
            mishandle, or have your private keys stolen, you may permanently
            lose access to your associated virtual currencies, and Botfather LLC
            is not responsible and will not be held liable for such loss.
          </p>
          <p>
            6.13. <b>Non-Liability for User Non-Compliance:</b> You acknowledge
            that Botfather LLC is not responsible for any loss, damage, or
            liability arising from your failure to comply with the terms set
            forth in this Agreement. You are solely responsible for ensuring
            that your actions align with the stipulations outlined herein.
          </p>
          <p>
            6.14. <b>Market Volatility and Transaction Variables:</b> You
            understand and acknowledge that markets for blockchain-based digital
            assets can be highly volatile and subject to various factors such as
            adoption, speculation, technology, security, and regulatory
            measures. You are also aware that the cost and speed of transactions
            with blockchain-based systems are variable and can fluctuate
            dramatically at any given time. Botfather LLC disclaims any
            responsibility for these variables and risks associated with the
            Protocol and cannot be held liable for any losses you may incur
            during your use of the Software.
          </p>
          <p>
            6.15. <b>Third-Party Resources and Promotions:</b> The Software may
            contain references or links to third-party resources or promotions
            that Botfather LLC does not own or control. We do not endorse or
            assume any responsibility for these third-party resources or
            promotions. If you choose to access these resources or participate
            in these promotions, you do so entirely at your own risk, and the
            Terms outlined in this Agreement do not apply to your dealings with
            these third parties. We are expressly relieved of any and all
            liability arising from your use of such resources or participation
            in such promotions.
          </p>
          <p>
            6.16. <b>Disclaimer of Fiduciary Duties:</b> As previously stated in
            Article 5, this Agreement does not create or impose any fiduciary
            duties on Botfather LLC. To the fullest extent permitted by law, you
            acknowledge and agree that we owe no fiduciary duties or liabilities
            to you or any other party. If any such duties or liabilities exist
            at law or in equity, they are hereby irrevocably disclaimed, waived,
            and eliminated. The only duties and obligations that we owe you are
            those expressly outlined in this Agreement.
          </p>
          <br />
          <b>Article 7: Intellectual Proprietary Rights</b>
          <p>
            7.1. <b>Ownership and Use of Intellectual Property:</b> Botfather
            LLC is the sole and exclusive owner of all intellectual property
            rights in and to the Software and its contents, including, but not
            limited to, software, text, images, trademarks, service marks,
            copyrights, patents, and designs, which are protected under U.S.
            federal laws (including the Lanham Act, Copyright Act, and Patent
            Act), relevant state laws, and international intellectual property
            laws and treaties. Unauthorized use of such intellectual property
            may violate these laws and we reserve all rights to seek remedies
            available under such laws.
          </p>
          <p>
            7.2. <b>Unauthorized Usage:</b> Unless expressly authorized by us in
            writing, you may not copy, modify, adapt, rent, license, sell,
            publish, distribute, or otherwise permit any third party to access
            or use the Software or any of its contents. This expressly includes
            the prohibition of reverse engineering, decompiling, or
            disassembling any part of the Software, pursuant to the U.S. Digital
            Millennium Copyright Act (DMCA) and the EU Directive 2009/24/EC.
          </p>
          <p>
            &nbsp;&nbsp;<b>I. Reverse Engineering:</b> You agree not to engage
            in the reverse engineering, disassembly, decompilation, or other
            exploitation of the Software, whether whole or in part, to
            ascertain, derive, or appropriate for any reason or purpose, the
            source code or underlying algorithms of the Software, or any other
            non-public information or processes used by Botfather LLC, except
            and only to the extent that such activity is expressly permitted by
            applicable law notwithstanding this limitation.
          </p>
          <p>
            &nbsp;&nbsp;<b>II. Legal Consequences:</b> Violation of these
            restrictions may result in severe civil and criminal penalties under
            U.S., EU, UN, and other applicable laws, including the U.S.
            Copyright Act (17 U.S.C. §§ 501 and 506), the EU Directive
            2009/24/EC on the legal protection of computer programs, and the
            WIPO Copyright Treaty to which the U.S., EU, and 100+ other
            countries are parties. Potential penalties can include substantial
            fines and imprisonment.
          </p>
          <p>
            &nbsp;&nbsp;<b>III. Enforcement:</b> In the event of any breach of
            this clause, Botfather LLC reserves the right to take appropriate
            legal action, including, but not limited to, seeking injunctive
            relief and damages. By using the Software, you agree that Botfather
            LLC has the right, at its sole discretion and at your expense, to
            enforce this clause by determining whether you are using the
            Software in violation of these terms, obtaining injunctions and
            orders for specific performance, and suing and seeking damages,
            among other remedies.
          </p>
          <p>
            7.3. <b>User Generated Intellectual Property:</b> You will retain
            ownership of all intellectual property rights in any information and
            materials you submit or create through the Software. By creating,
            uploading or otherwise making available such information or
            materials, you grant us a worldwide, royalty-free, irrevocable,
            sub-licensable, and transferable license to use, reproduce,
            distribute, create derivative works from, display, and perform this
            data in any manner we see fit in accordance with applicable laws and
            regulations.
          </p>
          <p>
            7.4. <b>Feedback:</b> We welcome your comments, bug reports, ideas
            or other feedback about the Software (collectively, “Feedback”). By
            submitting any Feedback, you grant us a perpetual, worldwide,
            exclusive, royalty-free, sub-licensable and transferable license to
            use, reproduce, distribute, prepare derivative works of, display,
            and perform such Feedback for any purpose, without need for further
            compensation to you and without confidentiality obligations on our
            part.
          </p>
          <p>
            7.5. <b>User License:</b> Subject to your compliance with the Terms,
            we grant you a limited, non-exclusive, non-transferable, revocable
            license to access and use the Software for your personal,
            non-commercial use only. We may revoke this license at our
            discretion without notice. Any use of the Software not expressly
            permitted by the Terms is strictly prohibited. The open-source
            components of the Software, running on the public Ethereum, other
            blockchains, or Ethereum layer 2 solutions, are not our proprietary
            property and are subject to the respective open-source licenses.
          </p>
          <p>
            7.6. <b>Blockchain Interactions:</b> The Software interfaces with
            the Protocol, which is comprised entirely of open-source software
            running on the public Ethereum and other blockchains or Ethereum
            layer 2 solutions and is not our proprietary property. The Protocol
            may also run on other blockchains to which the same clause applies.
            Your interactions with the Protocol are subject to their respective
            open-source licenses and not the purview of Botfather LLC.
          </p>
          <br />
          <b>Article 8: Indemnification</b>
          <p>
            8.1. <b>Agreement to Indemnify:</b> You agree to indemnify, defend,
            and hold harmless Botfather LLC, and our officers, directors,
            employees, contractors, agents, affiliates, and subsidiaries from
            and against all claims, damages, obligations, losses, liabilities,
            costs, legal fees, or other expenses arising out of or in any way
            connected with:
          </p>
          <p>
            {" "}
            a. your access to or use of the Software, including but not limited
            to any content you submit or transmit through the Software, as per
            Title 17 of the United States Code (U.S. Copyright Law), the U.S.
            Computer Fraud and Abuse Act (CFAA), and Regulation (EU) 2016/679
            (General Data Protection Regulation);
          </p>
          <p>
            {" "}
            b. your breach of these Terms, any third-party rights (including
            without limitation any copyright, property, or privacy rights), or
            any other applicable laws, regulations, or rules in your
            jurisdiction. This includes, but is not limited to, violations of
            the U.S. Lanham Act, U.S. Digital Millennium Copyright Act (DMCA),
            and the EU Directive 2001/29/EC (Copyright Directive); and
          </p>
          <p>
            {" "}
            c. any other party’s access to and use of the Software with your
            assistance or using any device or account that you own or control,
            as per the U.S. Electronic Communications Privacy Act (ECPA) and the
            EU Directive 2002/58/EC (ePrivacy Directive).
          </p>
          <p>
            8.2. <b>Legal Defense:</b> In the event we are required to respond
            to a third-party or law enforcement subpoena or court order
            applicable to your data or use of the Software, you agree to
            reimburse us for our reasonable legal fees, as well as our
            employees' and contractors' time and materials spent responding to
            the subpoena or court order at our then-current hourly rates.
          </p>
          <p>
            8.3. <b>Survival:</b> The obligations in this Article 8 will survive
            any expiration or termination of these Terms.
          </p>
          <br />
          <b>ATRICLE 9: Limitation of Liability</b>
          <p>
            9.1. <b>Limitations: </b>To the maximum extent permitted under
            applicable law, under no circumstances shall Botfather LLC, our
            officers, directors, employees, contractors, agents, affiliates, or
            subsidiaries be liable for any indirect, punitive, incidental,
            special, consequential, or exemplary damages, including but not
            limited to damages for loss of profits, goodwill, use, data, or
            other intangible losses (even if we have been advised of the
            possibility of such damages), arising out of, or resulting from, any
            access to or use of the Software. This includes, but is not limited
            to, damages resulting from or relating to:
          </p>
          <p>
            &nbsp;&nbsp; a. errors, mistakes, or inaccuracies of content, as per
            Section 230 of the U.S. Communications Decency Act;
          </p>
          <p>
            &nbsp;&nbsp; b. personal injury or property damage, of any nature
            whatsoever, resulting from any access to or use of the Software;
          </p>
          <p>
            &nbsp;&nbsp; c. any unauthorized access to or use of our servers or
            any personal information stored therein, in accordance with the U.S.
            Computer Fraud and Abuse Act (CFAA) and the EU General Data
            Protection Regulation (GDPR);
          </p>
          <p>
            &nbsp;&nbsp; d. any interruption or cessation of transmission to or
            from the Software;
          </p>
          <p>
            &nbsp;&nbsp; e. any bugs, viruses, Trojan horses, or similar malware
            which may be transmitted to or through the Software by any third
            party, as per Section 230 of the U.S. Communications Decency Act;
          </p>
          <p>
            &nbsp;&nbsp; f. any errors or omissions in any content, or any loss
            or damage of any kind incurred as a result of the use of any content
            posted, emailed, transmitted, or otherwise made available through
            the Software; or
          </p>
          <p>
            &nbsp;&nbsp; g. the defamatory, offensive, or illegal conduct of any
            third party.
          </p>
          <p>
            9.2. <b>Damage Cap:</b> In no event shall Botfather LLC, our
            officers, directors, employees, contractors, agents, affiliates, or
            subsidiaries be liable to you for any claims, proceedings,
            liabilities, obligations, damages, losses, or costs in an amount
            exceeding the greater of (i) the amount you paid to us in the past
            twelve months for access to and use of the Software, or (ii)
            $100.00. This limitation of liability applies regardless of the
            legal theory of the claim, whether based on contract, tort,
            negligence, strict liability, or any other legal theory, and even if
            Botfather LLC has been advised of the possibility of such damages.
          </p>
          <p>
            9.3. <b>Jurisdictional Limitation:</b> This limitation of liability
            section applies whether the alleged liability is based on contract,
            tort, negligence, strict liability, or any other basis. If you
            reside outside of the United States, this section may not apply to
            you as some jurisdictions do not allow the exclusion or limitation
            of incidental or consequential damages, so the above limitations or
            exclusions may not apply to you. This limitation of liability shall
            apply to the fullest extent permitted by law in the applicable
            jurisdiction terms may not apply to you. This limitation of
            liability shall apply to the fullest extent permitted by law.
          </p>
          <br />
          <b>ARTCILE 10: Arbitration and Class Action Waiver</b>
          <p>
            10.1. <b>Binding Arbitration:</b> Except for disputes wherein either
            party seeks to bring an individual action in a small claims court or
            seeks injunctive or other equitable relief for the alleged unlawful
            use of intellectual property rights, including copyrights,
            trademarks, trade names, logos, trade secrets, or patents, under the
            Digital Millennium Copyright Act or the Lanham Act, you and
            Botfather LLC:
          </p>
          <p>
            &nbsp;&nbsp; a. expressly waive any rights to have any and all
            disputes or claims arising from these Terms, your use or access to
            the Software, or any other disputes with Botfather LLC (collectively
            referred to as "Disputes") resolved in a court;
          </p>
          <p>
            &nbsp;&nbsp; b. relinquish any right to a jury trial pursuant to the
            Federal Arbitration Act (FAA, 9 U.S.C. § 1 et seq.).
          </p>
          <p>
            &nbsp;&nbsp; c. Instead, you and Botfather LLC agree to arbitrate
            Disputes that are not resolved informally (as described below)
            through binding arbitration. Binding arbitration refers to the
            referral of a Dispute to one or more neutral arbitrators who have
            the authority to resolve the Dispute by making a final and binding
            decision, as opposed to having the Dispute decided by a judge or
            jury in court.
          </p>
          <p>
            10.2. <b>Class Action Waiver:</b> You and Botfather LLC agree that
            any Dispute is personal to you and Botfather LLC and should be
            resolved solely on an individual basis. Any such dispute will be
            resolved solely through individual arbitration, and will not be
            brought as a class arbitration, class action, or any other type of
            representative proceeding. Pursuant to the Federal Arbitration Act
            (FAA, 9 U.S.C. § 1 et seq.) and the precedent established by AT&T
            Mobility LLC v. Concepcion, 563 U.S. 333 (2011), neither party
            agrees to class arbitration or to an arbitration wherein an
            individual attempts to resolve a Dispute as a representative of
            another individual or group of individuals. Further, you and
            Botfather LLC agree that a Dispute cannot be brought as a class or
            other type of representative action, whether within or outside of
            arbitration, or on behalf of any other individual or group of
            individuals.
          </p>
          <p>
            10.3.{" "}
            <b>
              No Class Arbitrations, Class Actions or Representative Actions:
            </b>{" "}
            In line with the policy of a personalized dispute resolution
            process, you and Botfather LLC mutually agree that any dispute
            arising out of or in relation to these Terms, the Software, or your
            relationship with Botfather LLC will be resolved exclusively on an
            individual basis. Any such dispute will be conducted through
            individual arbitration, precluding any form of class arbitration,
            class action, or any other type of representative proceeding.
          </p>
          <p>
            &nbsp;&nbsp; a. In accordance with the Federal Arbitration Act (FAA,
            9 U.S.C. § 1 et seq.) and the precedent established by the Supreme
            Court in AT&T Mobility LLC v. Concepcion, 563 U.S. 333 (2011),
            neither party agrees to or acknowledges the validity of class
            arbitration or any arbitration wherein an individual seeks to
            resolve a dispute on behalf of another individual or group of
            individuals.
          </p>
          <p>
            &nbsp;&nbsp; b. Furthermore, you and Botfather LLC categorically
            agree that a dispute cannot be brought as a class action, a
            consolidated action, a private attorney general action, or any other
            kind of representative action, whether within or outside the purview
            of arbitration, or on behalf of any other individual or group of
            individuals. This explicitly precludes any dispute from being
            decided in court by a judge or a jury on a class-wide, collective,
            consolidated, or representative basis.
          </p>
          <p>
            &nbsp;&nbsp; c. This class action waiver is an integral and
            fundamental aspect of the arbitration agreement between you and
            Botfather LLC, and is non-severable from the remaining portions of
            this agreement.{" "}
          </p>
          <p>
            10.4. <b>Notice and Informal Dispute Resolution: </b>Before either
            party may seek arbitration, the party must first send to the other
            party a written Notice of Dispute ("Notice") describing the nature
            and basis of the claim or dispute, and the requested relief. The
            Notice to Botfather LLC should be sent via certified mail to:
            Botfather LLC, [official business address]. Upon receipt of such
            Notice, the receiving party has a 30-day period in which we may
            agree to resolve the Dispute on an informal basis, which begins upon
            receipt of the Notice. If we do not come to an agreement within this
            30-day period, either party may proceed to commence arbitration.
          </p>
          <p>
            10.5. <b>Content of the Notice: </b>The Notice must include: (1)
            your full legal name, postal address, and an accessible email
            address; (2) a comprehensive explanation of the nature or basis of
            the Dispute; (3) a detailed statement of the specific remedy that
            you are seeking.
          </p>
          <p>
            10.6. <b>Arbitration Process:</b> If the parties cannot agree on how
            to resolve the Dispute within 30 days after the receiving party
            receives the Notice, either party may initiate arbitration
            proceedings. Arbitration will be governed by the Commercial
            Arbitration Rules and Mediation Procedures of the American
            Arbitration Association ("AAA") as modified by these Terms, and will
            be administered by the AAA.
          </p>
          <p>
            10.7. <b>Time Limitation on Claims and Arbitrations:</b> You and
            Botfather LLC agree that any arbitration or claim must be commenced
            or filed by you or Botfather LLC within one (1) year of the date the
            Dispute first arose, otherwise the underlying claim is permanently
            barred (which means that you and Botfather LLC will no longer have
            the right to assert such claim regarding the Dispute). You
            understand and acknowledge that you are forgoing the right to pursue
            or have a dispute resolved as a class, collective, or representative
            action, or to participate in any such class, collective, or
            representative proceeding.
          </p>
          <p>
            10.8. <b>Location and Manner of Arbitration:</b> Unless you and
            Botfather LLC agree otherwise, the arbitration must take place in
            the county of your billing address or in Miami-Dade County, Florida,
            United States, if your billing address is outside of the United
            States. If your claim is not for more than $10,000, we agree that
            you may choose whether the arbitration will be conducted solely on
            the basis of documents submitted to the arbitrator, through a
            telephonic hearing, or by an in-person hearing as determined by the
            arbitration procedures.
          </p>
          <p>
            10.9. <b>Governing Law and Venue:</b> This Agreement and your use of
            the Software are governed by and construed in accordance with the
            laws of the State of Florida, United States of America, without
            regard to its principles of conflicts of law. You agree that any
            dispute arising out of or relating to this Agreement or your use of
            the Software will be subject to the exclusive jurisdiction and venue
            of the state and federal courts located in Miami, Florida. This
            clause is intended to be as broad and inclusive as permitted by law.
            This choice of jurisdiction does not prevent us from seeking
            injunctive relief with respect to a violation of intellectual
            property rights or confidentiality obligations in any appropriate
            jurisdiction.
          </p>
          <p>
            10.10. <b>Arbitration:</b> Except where prohibited by law, any
            controversy, claim or dispute arising out of or relating to this
            Agreement, or the breach thereof, shall be resolved by arbitration
            administered by the American Arbitration Association ("AAA") under
            its Commercial Arbitration Rules. The arbitration shall take place
            in Miami, Florida, in English. The arbitration shall be governed by
            the United States Federal Arbitration Act (9 U.S.C. §§ 1 et seq.),
            and judgment on the award rendered by the arbitrator(s) may be
            entered in any court having jurisdiction thereof. (Florida Statutes
            Title VI. Civil Practice and Procedure § 682.01-682.15; §
            44.1011(1))
          </p>
          <p>
            10.11. <b>Arbitration Costs:</b> Payment of all filing,
            administration, and arbitrator fees will be governed by the AAA's
            rules. In the event that you are able to demonstrate that the costs
            of arbitration will be prohibitive as compared to the costs of
            litigation, Botfather LLC will pay as much of your filing,
            administrative, and arbitrator fees in connection with the
            arbitration as the arbitrator deems necessary to prevent the
            arbitration from being cost-prohibitive.
          </p>
          <p>
            10.12. <b>Severability:</b> If the prohibition against class actions
            and other claims brought on behalf of third parties contained above
            is found to be unenforceable, then all of the preceding language in
            this Arbitration section will be null and void. In such case, you
            and we agree to exclusive jurisdiction and venue in the state and
            federal courts located in Miami, Florida for any court proceedings.
          </p>
          <p>
            10.13. <b>Authority of Arbitrator:</b> The arbitrator, and not any
            federal, state, or local court or agency, shall have exclusive
            authority to resolve all disputes arising out of or relating to the
            interpretation, applicability, enforceability, or formation of this
            Agreement, including but not limited to any claim that all or any
            part of this Agreement is void or voidable, or whether a claim is
            subject to arbitration. The arbitration will decide the rights and
            liabilities, if any, of you and us. The arbitration proceeding will
            not be consolidated with any other matters or joined with any other
            cases or parties.
          </p>
          <p>
            &nbsp;&nbsp; a. The arbitrator shall have the authority to grant
            motions dispositive of all or part of any claim. The arbitrator
            shall have the authority to award monetary damages and to grant any
            non-monetary remedy or relief available to an individual under
            applicable law, the AAA Rules, and this Agreement. The arbitrator
            has the same authority to award relief on an individual basis that a
            judge in a court of law would have. The award of the arbitrator is
            final and binding upon you and us. This clause is subject to the
            Federal Arbitration Act ("FAA"), 9 U.S.C. §§ 1-16, as amended.
          </p>
          <p>
            &nbsp;&nbsp; b. The arbitrator has the right to decide on issues of
            arbitrability and jurisdiction, as well as procedural matters, and
            is empowered to grant whatever relief would be available in a court
            under law or in equity. The arbitrator's award shall be written and
            shall be binding on the parties and may be entered as a judgment in
            any court of competent jurisdiction, specifically any Florida state
            court or United States District Court for the District of Florida.
          </p>
          <p>
            &nbsp;&nbsp; c. In no event will the arbitrator have the authority
            to award damages, remedies or awards that conflict with these Terms.
            However, the arbitrator will not have the authority to conduct any
            form of class or collective arbitration nor join or consolidate
            claims by or for individuals.
          </p><br/>
          <b>Article 11:  Final Provisions</b>
          <p>1. <b>Amendments:</b> Botfather LLC reserves the right to amend any portion of these Terms at any time by posting the revised version of these Terms with an updated revision date. The changes will become effective, and shall be deemed accepted by you, upon the first use or access of the Software following the posting of the revised Terms, and shall apply prospectively with respect to your use of the Software and any transactions initiated after the posting date. If you do not agree with any such modification, your sole and exclusive remedy is to cease your use of the Software.</p>
          <p>2. <b>Entire Agreement:</b> These Terms, along with any additional terms, rules and conditions of participation that may be posted on Botfather LLC's website, including the Privacy Policy, constitute the entire agreement between you and Botfather LLC with respect to the Software and supersede any prior agreements, whether oral or written.</p>
          <p>3. <b>Privacy Policy:</b> The Privacy Policy elucidates the ways in which we collect, use, store, and disclose your personal information. By using the Software, you consent to the collection, use, storage, and disclosure of your data in accordance with the Privacy Policy. While we will comply with all valid subpoena requests, we will carefully scrutinize each request to ensure its legality, and we reserve the right to challenge any request that we deem to be invalid, excessively broad, or unconstitutional. We use commercially reasonable safeguards to maintain the integrity and security of your personally identifiable information ("PII") and aggregate data. However, we cannot guarantee that unauthorized third parties will never be able to obtain or use your PII or aggregate data for illicit purposes. You acknowledge that you provide your PII and aggregate data at your own risk.</p>
          <p>4. <b>Survival:</b> Upon termination of these Terms for any reason, all rights and obligations of the parties that by their nature should survive, will survive such termination.</p>
          <p>5. <b>Headings:</b> The headings identifying the various sections and subsections of these Terms are for reference only and do not define, modify, expand, or limit any of the provisions of these Terms, nor affect their interpretation. They do not define, modify, expand, or limit any of the provisions of these Terms, nor affect their interpretation.</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};
