import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import $ from "jquery";
import { Button, Form, Modal } from "react-bootstrap";
import { Terms } from "./Terms";
import { Privacy } from "./Privacy";

export const MailChimpForm = ({ status, message, onValidated }) => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();
  const {
    register: reg,
    handleSubmit: handleAgree,
    formState: { errors: err },
  } = useForm();
  const [modalShow, setModalShow] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  useEffect(() => {
    if (status === "success") reset();
    if (status && status !== "sending") {
      setTimeout(() => {
        $(".alert-messages").empty();
      }, 6000);
    }
  }, [status, reset]);

  const subscribeMail = (data) => {
    setModalShow(true);
  };

  const agreeConditions = (data) => {
    reset();
    setModalShow(false);
    onValidated({
      EMAIL: getValues("email"),
    });
  };

  return (
    <div className="field-container">
      <form className="form" onSubmit={handleSubmit(subscribeMail)}>
        <div className="field-group">
          <input
            type="email"
            name="MERGE0"
            id="MERGE0"
            placeholder="Enter Email..."
            {...register("email", {
              required: "Email is required",
              maxLength: {
                value: 50,
                message: "Email is too long",
              },
            })}
          />
          <span>
            <input type="submit" value="Send" />
          </span>
        </div>
        {errors.email && (
          <div className="p-2 error-msg">{errors.email.message}</div>
        )}
      </form>
      {status === "sending" && (
        <div className="alert alert--sending text-left mb-0">
          Sending request...
        </div>
      )}
      {status === "error" && (
        <div
          className="alert-messages alert--error text-danger text-left mb-0 mt-2"
          dangerouslySetInnerHTML={{ __html: message }}
          role="alert"
        />
      )}
      {status === "success" && (
        <div
          className="alert-messages alert--success text-success text-left mb-0 mt-2"
          dangerouslySetInnerHTML={{ __html: message }}
          role="alert"
        />
      )}
      <Modal className="foot-check"
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          reset();
        }}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Form onSubmit={handleAgree(agreeConditions)}>
          <Modal.Body >
            <div className="agree-condition">
              <input
                type="checkbox"
                id="term"
                {...reg("terms", {
                  required: "Please agree to terms and conditions",
                })}
              />
              <label htmlFor="term">
                I've read and agree with Botfather{" "}
                <span
                  className="view-reason"
                  onClick={() => setShowTerms(true)}
                >
                  Terms
                </span>{" "}
                and{" "}
                <span
                  className="view-reason"
                  onClick={() => setShowPrivacy(true)}
                >
                  Privacy Policy
                </span>
                .
              </label>
            </div>
            {err.terms && (
              <span className="error-msg">{err.terms.message}</span>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" className="btn btn-danger">Subscribe</Button>
            <Button
              onClick={() => {
                setModalShow(false);
                reset();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Terms show={showTerms} onHide={() => setShowTerms(false)} />
      <Privacy show={showPrivacy} onHide={() => setShowPrivacy(false)} />
    </div>
  );
};
