import { useContext } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GlobalContext } from "../context/GlobalContext";
import { ChangePasswordApi } from "../services/UserServices";

export const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);

  const changePass = (data) => {
    if (data.newPassword !== data.confirmPassword) {
      setError("confirmPassword", {
        message: "Password and confirm password should be same",
      });
    } else if (data.newPassword === data.oldPassword) {
      setError("confirmPassword", {
        message: "Old password and new password should be different.",
      });
    } else {
      globalContext.setLoader(true);
      ChangePasswordApi(data)
        .then((res) => {
          globalContext.setLoader(false);
          Swal.fire(
            "Success",
            "Password changed successfully.",
            "success"
          ).then(() => navigate("/account"));
          reset();
        })
        .catch((err) => {
          globalContext.setLoader(false);
          Swal.fire("Error", err.response.data.message, "error");
          console.log("Error: ", err);
        });
    }
  };

  return (
    <section className="signup-bot">
      <div className="login  m-auto">
        <div className="tab-heading-area">
          <h1 className="mb-4">Change Password</h1>
        </div>
        <Form onSubmit={handleSubmit(changePass)}>
          <div className="row">
            <div className="login-data col-lg-12">
              <Form.Group className="mb-3" controlId="formBasicpass">
                <Form.Control
                  type="password"
                  placeholder="Old Password"
                  {...register("oldPassword", {
                    onChange: (e) => {
                      if (e.target.value === " ") {
                        setValue("oldPassword", "");
                      }
                    },
                    required: "Old password is required",
                  })}
                />
                {errors.oldPassword && (
                  <span className="error-msg">
                    {errors.oldPassword.message}
                  </span>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicpass">
                <Form.Control
                  type="password"
                  placeholder="New Password"
                  {...register("newPassword", {
                    onChange: (e) => {
                      if (e.target.value === " ") {
                        setValue("newPassword", "");
                      }
                    },
                    required: "New Password is required",
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])(?!.*\s).{8,}$/,
                      message:
                        "Password must contain 1 lower case, 1 upper case, 1 number and 1 special character.",
                    },
                    minLength: {
                      value: 8,
                      message: " Password must be at least 8 characters long",
                    },
                  })}
                />
                {errors.newPassword && (
                  <span className="error-msg">
                    {errors.newPassword.message}
                  </span>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicpass">
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  {...register("confirmPassword", {
                    onChange: (e) => {
                      if (e.target.value === " ") {
                        setValue("confirmPassword", "");
                      }
                    },
                    required: "Confirm password is required",
                  })}
                />
                {errors.confirmPassword && (
                  <span className="error-msg">
                    {errors.confirmPassword.message}
                  </span>
                )}
              </Form.Group>
            </div>

            <div className="col-lg-12 login-data">
              <button className="btn btn-sign change-passwd">Change Password</button>
              <p className="signup-back-btn back-to-login">
                <Link to="/account">
                  <img
                    src={require("../assets/images/back-btn.png")}
                    alt="back"
                  />
                  Back
                </Link>
              </p>
            </div>
          </div>
        </Form>
      </div>
    </section>
  );
};
