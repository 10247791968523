import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { MailChimpForm } from "./MailChimpForm";
import decod from "../../assets/images/discord.png";
import { Privacy } from "./Privacy";
import { Terms } from "./Terms";

const Footer = () => {
  const mailChimpU = "f58f6b906593afc22e25ccc42";
  const mailChimpId = "691fd9586b";
  const mailChimpLink = `https://thebotfather.us21.list-manage.com/subscribe/post?u=${mailChimpU}&id=${mailChimpId}`;
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  return (
    <section className="footer">
      <Container>
        <Row className="align-items-center">
          <Col md={3} className="mb-4">
            <div className="footer-logo">
              <img
                className="zoom-in-zoom-out"
                src={require("../../assets/images/botfather-logo.png")}
                alt="img"
              />
            </div>
          </Col>
          <Col md={4} className="mb-4">
            <div className="social-links">
              <h6>stay connected</h6>
              <ul>
                <li>
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </li>
                <li>
                  <a href="https://twitter.com/BOTFATHERinfo" target="_blank">
                  <i
                    className="fa fa-twitter"
                    aria-hidden="true"
                  ></i>
                  </a>
                </li>

                <li>
                
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                
                  <a href="https://t.me/BOTFATHERAnnouncements" target="_blank">
                  <i
                    className="fa fa-telegram"
                    aria-hidden="true"
                  ></i>
                  </a>
                </li>
                <li>
                <a href="https://discord.gg/HT9tHDMWwF" target="_blank">
                  <img src={decod} alt="discord" role="button" />
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col md={5} className="mb-4">
            <div className="footer-email-area">
              <h6>subscribe to our newsletter</h6>
              <div className="mb-3">
                <MailchimpSubscribe
                  url={mailChimpLink}
                  render={({ subscribe, status, message }) => (
                    <MailChimpForm
                      status={status}
                      message={message}
                      onValidated={(formData) => subscribe(formData)}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col md={6}>
            <div className="footer-bottom">
              <p> Copyright {new Date().getFullYear()} All Rights Reserved.</p>
            </div>
          </Col>
          <Col md={6}>
            <div className="footer-bottom-right">
              <ul>
                <li onClick={() => setShowTerms(true)}>Terms</li>
                <li onClick={() => setShowPrivacy(true)}>Privacy Policy</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <Terms show={showTerms} onHide={() => setShowTerms(false)} />
      <Privacy show={showPrivacy} onHide={() => setShowPrivacy(false)} />
    </section>
  );
};
export default Footer;
