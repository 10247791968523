import React, { useContext } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const globalContext = useContext(GlobalContext);

  const logout = () => {
    sessionStorage.clear();
    globalContext.setLoggedIn(false);
    globalContext.setUserInfo({});
    navigate("/");
  };

  return (
    <section className="header">
      <Navbar expand="sm">
        <Container>
          <Navbar.Brand>
            <img
              onClick={() => navigate("/")}
              src={require("../../assets/images/botfather-logo.png")}
              alt="img"
              className="zoom-in-zoom-out"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                className={location.pathname === "/" ? "active" : ""}
                onClick={() => navigate("/")}
              >
                Home
              </Nav.Link>
              <Nav.Link
                className={
                  location.pathname === "/home" ||
                  location.pathname === "/subscriptionplan"
                    ? "active"
                    : ""
                }
                onClick={() => navigate("/home?plan=Yearly")}
              >
                Membership Plan
              </Nav.Link>
              <Nav.Link
                className={location.pathname === "/aboutus" ? "active" : ""}
                onClick={() => navigate("/aboutus")}
              >
                About Us
              </Nav.Link>
              <Nav.Link
                className={location.pathname === "/faq" ? "active" : ""}
                onClick={() =>
                  window.open("https://thebotfather.gitbook.io/faq/", "_blank")
                }
              >
                FAQ
              </Nav.Link>
              {!globalContext.loggedIn ? (
                <>
                  <Nav.Link
                    onClick={() => navigate("/login")}
                    className={location.pathname === "/login" ? "active" : ""}
                  >
                    Login
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => navigate("/signup")}
                    className={location.pathname === "/signup" ? "active" : ""}
                  >
                    Sign up
                  </Nav.Link>
                </>
              ) : (
                <>
                  <Nav.Link
                    onClick={() => navigate("/account")}
                    className={
                      location.pathname === "/account" ||
                      location.pathname === "/changepassword"
                        ? "active"
                        : ""
                    }
                  >
                    Manage Account
                  </Nav.Link>
                  <Nav.Link onClick={() => logout()}>Logout</Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </section>
  );
};
export default Header;
