import { Card, Container, Row, Col } from "react-bootstrap";
import bannerright from "../assets/images/bannershap-right.png";
import phone from "../assets/images/phone.png";
import telegram from "../assets/images/telegram.png";
import fox from "../assets/images/signup.png";
import product from "../assets/images/view-pro.png";
import subscription from "../assets/images/subscribe.png";
import checkout from "../assets/images/checkout-main.png";
import gift from "../assets/images/gift.png";
import bit from "../assets/images/bitcoin.png";
import arrowbanner from "../assets/images/btn-arrow.png";
import botlogo from "../assets/images/bot-logo.png";
import smile from "../assets/images/smile.png";
import box from "../assets/images/box.png";
import aword from "../assets/images/aword.png";
import aboutpic from "../assets/images/banner-right.png";
import play from "../assets/images/play-icon.png";
import line from "../assets/images/line-arrow.png";
import Carousel from "react-bootstrap/Carousel";
import crypto from "../assets/images/crypto-chart.png";
import botlight from "../assets/images/botfather-light.png";
import triangle from "../assets/images/triangle-left.png";
import first from "../assets/images/first.png";
import dogle from "../assets/images/dogle.png";
import snoopy from "../assets/images/snoopy.png";
import slide from "../assets/images/slide-pic.png";
import bannercoin from "../assets/images/banner-coin.png";
import { useNavigate } from "react-router-dom";

export const Landing = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="banner">
        <div className="banner-box">
          <div className="container">
            <Row className="align-items-center">
              <Col lg="9">
                <div className="banner-caption">
                  <h1>
                    BOTFATHER
                    <span>Limitless Possibilities.</span>
                  </h1>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    <br /> typesetting industry. Lorem Ipsum has
                    <br /> been the industry.
                  </p>
                  <button className="btn btn-try">
                    Try It Now <img src={arrowbanner} alt="banner-arrow" />
                  </button>
                </div>
              </Col>

              <Col lg="3">
                <div className="banner-pic">
                  <img
                    src={bannerright}
                    className="banner-right-figure"
                    alt="bannerpic"
                  />
                </div>
              </Col>
            </Row>
          </div>
          <img
            src={botlogo}
            alt="bot-lgo"
            className="bot-logo-banner gift mover"
          />
        </div>
      </div>

      <div className="happy-client-all">
        <Container>
          <h2>Numbers We Proud Of</h2>
          <Row>
            <Col lg="4">
              <Card>
                <div className="happy-client">
                  <div className="client-box">
                    <div className="figure">
                      <img src={smile} alt="smile" />
                    </div>

                    <div className="client-text">
                      <h4>751</h4>
                      <p>Happy Clients</p>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>

            <Col lg="4">
              <Card>
                <div className="happy-client">
                  <div className="client-box">
                    <div className="figure">
                      <img src={box} alt="smile" />
                    </div>

                    <div className="client-text">
                      <h4>751</h4>
                      <p>Total Software</p>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>

            <Col lg="4">
              <Card>
                <div className="happy-client">
                  <div className="client-box">
                    <div className="figure">
                      <img src={aword} alt="smile" />
                    </div>

                    <div className="client-text">
                      <h4>751</h4>
                      <p>Award Won</p>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="about-us">
        <Container>
          <Row className="align-items-center">
            <Col lg="6">
              <div className="figure-left">
                <img src={aboutpic} className="abt-us" />
              </div>
            </Col>

            <Col lg="6" className="pr-0">
              <div className="about-info">
                <h2>ABOUT US</h2>
                <p>
                  BotFather is the brainchild of a close-knit group of friends
                  and experienced retail crypto traders. Our journey began with
                  a shared passion for the dynamic world of cryptocurrencies and
                  a mutual frustration over the lack of accessible,
                  cost-effective tools available to the average trader.
                </p>
                <p>
                  After countless hours spent navigating clunky platforms,
                  wrestling with subpar tools, and feeling the sting of missed
                  trading opportunities, we decided to take matters into our own
                  hands. From this collective vision and experience, BotFather
                  was born.
                </p>
                <p>
                  Our mission is simple yet ambitious: to provide a reliable and
                  innovative crypto trading platform that's accessible and
                  affordable for traders at every level. We believe that
                  everyone deserves the chance to succeed in their crypto
                  trading journey, and we're committed to equipping them with
                  the tools they need to thrive.
                </p>
                <p>
                  Our platform combines our extensive trading experience with
                  state-of-the-art technology to offer a secure and
                  user-friendly environment where traders can confidently grow
                  and manage their portfolios. We're not just a trading
                  platform; we're a community dedicated to empowering each other
                  and breaking down the barriers in the crypto trading space.
                </p>
                <button
                  className="btn btn-learn"
                  onClick={() => navigate("/aboutus")}
                >
                  Learn More <img src={play} alt="play-icon" />
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="welcome">
        <Container>
          <div className="box">
            <h3>
              YOU ARE MOST WELCOME <br />
              IN <span>BOTFATHER</span> WORLD.
            </h3>
            <p>Start automating your crypto trading today!</p>

            <Carousel cols={2} rows={1} gap={10} loop>
              <Carousel.Item className="row">
                <Card className="col-lg-4">
                  <div className="slider-pic">
                    <img src={slide} alt="slide-pic" />
                  </div>
                  <div className="slide-text">
                    <h4>Reddit upvote bots</h4>
                    <p>Dedicated Bot Services</p>
                  </div>
                  <button className="btn btn-detail">
                    View Details <img src={play} alt="play-icon" />
                  </button>
                </Card>

                <Card className="col-lg-4">
                  <div className="slider-pic">
                    <img src={slide} alt="slide-pic" />
                  </div>
                  <div className="slide-text">
                    <h4>Reddit upvote bots</h4>
                    <p>Dedicated Bot Services</p>
                  </div>
                  <button className="btn btn-detail">
                    View Details <img src={play} alt="play-icon" />
                  </button>
                </Card>

                <Card className="col-lg-4">
                  <div className="slider-pic">
                    <img src={slide} alt="slide-pic" />
                  </div>
                  <div className="slide-text">
                    <h4>Reddit upvote bots</h4>
                    <p>Dedicated Bot Services</p>
                  </div>
                  <button className="btn btn-detail">
                    View Details <img src={play} alt="play-icon" />
                  </button>
                </Card>
              </Carousel.Item>
              <Carousel.Item className="col-lg-4">
                <Card className="col-lg-4">
                  <div className="slider-pic">
                    <img src={slide} alt="slide-pic" />
                  </div>
                  <div className="slide-text">
                    <h4>Reddit upvote bots</h4>
                    <p>Dedicated Bot Services</p>
                  </div>
                  <button className="btn btn-detail">
                    View Details <img src={play} alt="play-icon" />
                  </button>
                </Card>

                <Card className="col-lg-4">
                  <div className="slider-pic">
                    <img src={slide} alt="slide-pic" />
                  </div>
                  <div className="slide-text">
                    <h4>Reddit upvote bots</h4>
                    <p>Dedicated Bot Services</p>
                  </div>
                  <button className="btn btn-detail">
                    View Details <img src={play} alt="play-icon" />
                  </button>
                </Card>

                <Card className="col-lg-4">
                  <div className="slider-pic">
                    <img src={slide} alt="slide-pic" />
                  </div>
                  <div className="slide-text">
                    <h4>Reddit upvote bots</h4>
                    <p>Dedicated Bot Services</p>
                  </div>
                  <button className="btn btn-detail">
                    View Details <img src={play} alt="play-icon" />
                  </button>
                </Card>
              </Carousel.Item>
              <Carousel.Item className="col-lg-4">
                <Card className="col-lg-4">
                  <div className="slider-pic">
                    <img src={slide} alt="slide-pic" />
                  </div>
                  <div className="slide-text">
                    <h4>Reddit upvote bots</h4>
                    <p>Dedicated Bot Services</p>
                  </div>
                  <button className="btn btn-detail">
                    View Details <img src={play} alt="play-icon" />
                  </button>
                </Card>

                <Card className="col-lg-4">
                  <div className="slider-pic">
                    <img src={slide} alt="slide-pic" />
                  </div>
                  <div className="slide-text">
                    <h4>Reddit upvote bots</h4>
                    <p>Dedicated Bot Services</p>
                  </div>
                  <button className="btn btn-detail">
                    View Details <img src={play} alt="play-icon" />
                  </button>
                </Card>

                <Card className="col-lg-4">
                  <div className="slider-pic">
                    <img src={slide} alt="slide-pic" />
                  </div>
                  <div className="slide-text">
                    <h4>Reddit upvote bots</h4>
                    <p>Dedicated Bot Services</p>
                  </div>
                  <button className="btn btn-detail">
                    View Details <img src={play} alt="play-icon" />
                  </button>
                </Card>
              </Carousel.Item>

              {/* ... */}
            </Carousel>
          </div>
        </Container>
      </div>

      <div className="how-it">
        <Container>
          <div className="heading-top">
            <h2>
              HOW IT <span>WORKS</span>
            </h2>
            <p>It's easier than you think. Follow 4 simple easy steps</p>
          </div>
          <Row>
            <Col lg="3">
              <div className="icon-wallet">
                <img src={fox} alt="fox" />
                <p>
                  SIGNUP <span>01</span>
                </p>
              </div>
              <img src={line} alt="line-arrow" className="line-arrow" />
            </Col>

            <Col lg="3">
              <div className="icon-wallet">
                <img src={product} alt="product" />
                <p>
                  VIEW PRODUCTS <span>02</span>
                </p>
              </div>
              <img src={line} alt="line-arrow" className="line-arrow" />
            </Col>

            <Col lg="3">
              <div className="icon-wallet">
                <img src={subscription} alt="subscription" />
                <p>
                  SUBSCRIPTION <span>03</span>
                </p>
              </div>
              <img src={line} alt="line-arrow" className="line-arrow" />
            </Col>

            <Col lg="3">
              <div className="icon-wallet">
                <img src={checkout} alt="checkout" />
                <p>
                  CHECKOUT <span>04</span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="trading">
        <Container>
          <div className="crypto">
            <img src={crypto} alt="crypto-chart" />
          </div>
          <div className="trading-caption">
            <h2>
              {" "}
              World Class <br />
              Automated Trading
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. est,
              finibus ut congue sed, faucibus ut dui. Sed congue dapibus leo
              elementum posuere. Ut aliquam metus quis elementum. Lorem ipsum
              dolor sit amet, consectetur adipiscing.
            </p>

            <button className="btn btn-learn">
              Explore <img src={play} alt="play-icon" />
            </button>
          </div>
        </Container>
      </div>

      <div className="reddit">
        <Container>
          <Row className="align-items-center">
            <Col lg="6">
              <div className="bot-text">
                <h2>
                  REDDIT UPVOTE <span>BOTS</span>
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. est,
                  finibus ut congue sed, faucibus ut dui. Sed congue dapibus leo
                  elementum posuere.
                </p>{" "}
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. est,
                  finibus ut congue sed, faucibus ut dui. Sed congue dapibus leo
                  elementum posuere. ut congue sed, faucibus ut dui. Sed congue
                  dapibus leo elementum posuere.{" "}
                </p>
              </div>
            </Col>

            <Col lg="6">
              <div className="bot-figure">
                <img src={phone} alt="phone" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="telegram">
        <Container>
          <Row className="align-items-center">
            <Col lg="6">
              <div className="bot-figure">
                <img src={telegram} alt="phone" />
                <img src={triangle} alt="triangle" className="triangle" />
                <img src={triangle} alt="triangle" className="triangle-right" />
              </div>
            </Col>

            <Col lg="6">
              <div className="bot-text">
                <img src={botlight} alt="bot-light" className="bot-light" />
                <h2>
                  TELEGRAM WELCOME <span>BOTS</span>
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. est,
                  finibus ut congue sed, faucibus ut dui. Sed congue dapibus leo
                  elementum posuere.{" "}
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. est,
                  finibus ut congue sed, faucibus ut dui. Sed congue dapibus leo
                  elementum posuere. ut congue sed, faucibus ut dui. Sed congue
                  dapibus leo elementum posuere.{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="promoter-coins">
        <Container>
          <div className="prom-box">
            <h4>Promoted Coins</h4>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
            <div className="table-responsive">
              <table className="table table-responsive text-black bg-white radius-20">
                <tr>
                  <td>
                    <img src={first} alt="first-pick" />
                  </td>
                  <td>Folikuuni</td>
                  <td>$121</td>
                  <td>Launch in 3 day</td>
                  <td>
                    <a>
                      <i className="fa fa-arrow-up"></i>$121
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src={dogle} alt="first-pick" />
                  </td>
                  <td>Lady dogie</td>
                  <td>$121</td>
                  <td>26 days</td>
                  <td>
                    <a>
                      <i className="fa fa-arrow-up"></i>$121
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src={snoopy} alt="first-pick" />
                  </td>
                  <td>Snoopy inu</td>
                  <td>$121</td>
                  <td>10 days</td>
                  <td>
                    <a>
                      <i className="fa fa-arrow-up"></i>$121
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src={dogle} alt="first-pick" />
                  </td>
                  <td>SuperSafe</td>
                  <td>$121</td>
                  <td>Launch in 4 day</td>
                  <td>
                    <a>
                      <i className="fa fa-arrow-up"></i>$121
                    </a>
                  </td>
                </tr>
              </table>
            </div>
            <button className="btn btn-try">
              Explore Now <img src={arrowbanner} alt="banner-arrow" />
            </button>
            <img src={bannercoin} alt="bannercoin" className="bannercoins" />
          </div>
          <img src={gift} alt="gifts" className="gift mover" />
          <img src={bit} alt="bitcoin" className="bitcoin-pic mover" />
        </Container>
      </div>
    </>
  );
};
export default Landing;
