import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PayPalButtons } from "@paypal/react-paypal-js";
import Swal from "sweetalert2";
import { CancelPlan, ParticularPlan, UserData } from "../services/UserServices";
import { GlobalContext } from "../context/GlobalContext";
import { DateFormat } from "../Component/Common/DateFormat";
import { useForm } from "react-hook-form";
import { Terms } from "../Component/Common/Terms";
import { Privacy } from "../Component/Common/Privacy";
import freeplan from "../assets/images/free-trial.png";

const SubscriptionPlan = () => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const { register: reg, reset: res, watch } = useForm();
  const [query] = useSearchParams();
  const globalContext = useContext(GlobalContext);
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [plan, setPlan] = useState({});
  const [reasonModal, setReasonModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  useEffect(() => {
    const plan = query.get("id");
    setId(plan);
    getUserInfo();
    getPlanDetail(plan);
  }, [query]);

  const getPlanDetail = (id) => {
    globalContext.setLoader(true);
    ParticularPlan(id)
      .then((res) => {
        if (
          res.data.data.planStatus !== "ACTIVE" &&
          globalContext.userInfo?._subscription?._id !== id
        ) {
          Swal.fire("This plan is no longer available.").then(() =>
            navigate("/home?plan=Yearly")
          );
        }
        setPlan(res.data.data);
        globalContext.setLoader(false);
      })
      .catch((err) => {
        globalContext.setLoader(false);
        console.log("Error: ", err);
      });
  };

  const paypalSubscribe = (data, actions) => {
    let params = {
      plan_id: plan?.stripePlanId,
      custom_id: globalContext.userInfo._id,
    };
    if (
      globalContext.userInfo?._subscription &&
      globalContext.userInfo?.paypalSubscriptionId
    ) {
      return actions.subscription.revise(
        globalContext.userInfo?.paypalSubscriptionId,
        params
      );
    } else {
      return actions.subscription.create(params);
    }
  };

  const getUserInfo = () => {
    globalContext.setLoader(true);
    UserData()
      .then((res) => {
        sessionStorage.setItem("user-info", JSON.stringify(res.data.data));
        globalContext.setUserInfo(res.data.data);
        globalContext.setLoader(false);
      })
      .catch((err) => {
        globalContext.setLoader(false);
        console.log("Error: ", err);
      });
  };

  const onApprove = (data, actions) => {
    return actions.subscription.get().then((details) => {
      res();
      setModalShow(false);
      getUserInfo();
      Swal.fire({
        title: "Success",
        text: "Subscription plan purchased successfully. It might take some time to reflect.",
        icon: "success",
        showCancelButton: true,
        confirmButtonText: "Ok",
        cancelButtonText: "Download Software",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/account");
        }
      });
    });
  };

  const paypalOnError = (err) => {
    console.log("Error: ", err);
  };

  const closeReasonModal = () => {
    setReasonModal(false);
    reset({ reason: "" });
  };

  const cancelUserSubscription = (data) => {
    globalContext.setLoader(true);
    CancelPlan(data)
      .then((res) => {
        globalContext.setLoader(false);
        closeReasonModal();
        getUserInfo();
        getPlanDetail();
        Swal.fire(
          "Success",
          "Subscription plan cancelled successfully!",
          "success"
        );
      })
      .catch((err) => {
        globalContext.setLoader(false);
        closeReasonModal();
        getUserInfo();
        console.log("Error: ", err);
        if (err.response.status === 400)
          Swal.fire(err.response.data.message, "", "error");
      });
  };

  return (
    <section className="subscription-area">
      <Container>
        <div className="tab-heading-area">
          <h1>Buy Subscription Plan</h1>
        </div>
        <Row className="mt-5 justify-content-center">
          <Col xxl={3} xl={4} md={6} className="mb-3 free-trial-ava">
            {plan.hasTrial === "1" && (
              <img src={freeplan} alt="freeplan" className="free-trial" />
            )}
            <div className=" subscription-plan-area">
              <h6>{plan.planName}</h6>
              <h2>
                ${plan.planPrice}/<span>{plan.durationUnit}</span>
              </h2>
              <hr></hr>
              <ul>
                {plan?.benifits?.map((b, i) => (
                  <li key={i}>
                    <img src={require("../assets/images/tick.png")} alt="img" />{" "}
                    {b}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col xxl={4} xl={5} md={6} className="mb-3">
            <div className="subscription-area-right">
              <div className="subscription-area-top">
                <img src={require("../assets/images/paypal.png")} alt="img" />
                <div className="subscription-prize">
                  <h5>
                    <i className="fa fa-shopping-cart" aria-hidden="true"></i> $
                    {plan.planPrice}
                  </h5>
                </div>
              </div>
              <div className="main-all">
                <div className="subsciption-area-center">
                  <h5
                    className={`mb-3 ${
                      globalContext.userInfo?._subscription?._id === id
                        ? "disable-paypal"
                        : ""
                    }`}
                  >
                    Pay With PayPal
                  </h5>
                  <Button
                    variant="unset"
                    onClick={() => {
                      getPlanDetail(query.get("id"));
                      setModalShow(true);
                    }}
                    disabled={
                      globalContext.userInfo?._subscription?._id === id ||
                      globalContext.userInfo?.planPrice > plan.planPrice
                    }
                  >
                    PayPal
                  </Button>
                  {globalContext.userInfo?._subscription?._id === id && (
                    <h5 className="mt-2">
                      Next Billing Period:-{" "}
                      {DateFormat(globalContext.userInfo?.planExpiry)}
                    </h5>
                  )}
                  {globalContext.userInfo?._subscription?._id === id && (
                    <Button
                      variant="unset"
                      onClick={() => setReasonModal(true)}
                    >
                      Cancel Subscription
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {globalContext.userInfo?._subscription?._id === id && (
          <div className="disclaimer plan-detail">
            <h4>Disclaimer</h4>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, Lorem Ipsum is simply dummy text...
            </p>
          </div>
        )}
        {plan.planStatus && plan.planStatus !== "ACTIVE" && (
          <h6 className="note-disabled mt-4 text-white text-center">
            <span className="text-danger">Note:</span> Plan is disabled, Current
            purchase will be functional till{" "}
            {DateFormat(globalContext.userInfo?.planExpiry)} and then all
            featured will get disabled.
          </h6>
        )}
      </Container>
      <Modal
        show={reasonModal}
        onHide={() => closeReasonModal()}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Cancelation Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter reason here..."
            maxLength={128}
            {...register("reason", {
              onChange: (e) => {
                if (e.target.value.trim() === "") {
                  setValue("reason", "");
                }
              },
              required: "Reason is required",
            })}
          />
          {errors.reason && (
            <span className="error-msg">{errors.reason.message}</span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeReasonModal()}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={handleSubmit(cancelUserSubscription)}
          >
            Cancel Subscription
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        className="agree-paypal"
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          res();
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form className="agree-terms-condition">
          <Button
            className="cross-item"
            onClick={() => {
              setModalShow(false);
              res();
            }}
          >
            <i className="fa fa-times-circle"></i>
          </Button>
          <Modal.Body>
            <div className="agree-condition">
              <input
                type="checkbox"
                id="term"
                {...reg("terms", {
                  required: "Please agree to terms and conditions",
                })}
              />
              <label htmlFor="term">
                I've read and agree with Botfather{" "}
                <span
                  className="view-reason"
                  onClick={() => setShowTerms(true)}
                >
                  Terms
                </span>{" "}
                and{" "}
                <span
                  className="view-reason"
                  onClick={() => setShowPrivacy(true)}
                >
                  Privacy Policy
                </span>
                .
              </label>
            </div>
          </Modal.Body>
          {plan.stripePlanId && (
            <PayPalButtons
              style={{ layout: "horizontal" }}
              createSubscription={paypalSubscribe}
              disabled={
                globalContext.userInfo?._subscription?._id === id ||
                globalContext.userInfo?.planPrice > plan.planPrice ||
                !watch("terms")
              }
              onApprove={onApprove}
              catchError={paypalOnError}
              onError={paypalOnError}
              onCancel={paypalOnError}
            />
          )}
        </Form>
      </Modal>
      <Terms show={showTerms} onHide={() => setShowTerms(false)} />
      <Privacy show={showPrivacy} onHide={() => setShowPrivacy(false)} />
    </section>
  );
};
export default SubscriptionPlan;
