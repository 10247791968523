import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import { AboutUs } from "../pages/AboutUs";
import { ChangePassword } from "../pages/ChangePassword";
import { ForgotPassword } from "../pages/ForgotPassword";
import { Home } from "../pages/Home";
import Landing from "../pages/Landing";
import { Login } from "../pages/login";
import ManagePlan from "../pages/ManageAccount";
import { ResetPassword } from "../pages/ResetPassword";
import SignUp from "../pages/Signup";
import SubscriptionPlan from "../pages/SubscriptionPlan";
import { VerifyEmail } from "../pages/VerifyEmail";
import { ScrollToTop } from "./Common/ScrollToTop";
export const Routing = () => {
  const globalContext = useContext(GlobalContext);

  return (
    <ScrollToTop>
      <Routes>
        <>
          <Route path="*" element={<Landing />} />
          <Route path="/home" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
        </>
        {globalContext.loggedIn ? (
          <>
            <Route path="/subscriptionplan" element={<SubscriptionPlan />} />
            <Route path="/account" element={<ManagePlan />} />
            <Route path="/changepassword" element={<ChangePassword />} />
          </>
        ) : (
          <>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/verifyemail/:token" element={<VerifyEmail />} />
          </>
        )}
      </Routes>
    </ScrollToTop>
  );
};
