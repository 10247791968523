import { useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GlobalContext } from "../context/GlobalContext";
import { LoginUser, ResendEmailVerify } from "../services/UserServices";

export const Login = () => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const location = useLocation();
  const globalContext = useContext(GlobalContext);

  const resendLink = (errorMessage) => {
    Swal.fire({
      title: "Error",
      text: errorMessage,
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Resend Verification Link",
    }).then((result) => {
      if (result.isDismissed) {
        let params = {
          email: getValues("email"),
        };
        ResendEmailVerify(params)
          .then((res) => {
            console.log(res);
            Swal.fire(
              "Success",
              "Verification link resend successfully",
              "success"
            );
          })
          .catch((err) => {
            console.log("Error: ", err.response);
            Swal.fire("Error", err.response.data.message, "error");
          });
      }
    });
  };

  const loginUser = (data) => {
    globalContext.setLoader(true);
    data["email"] = data.email.toLowerCase();
    LoginUser(data)
      .then((res) => {
        sessionStorage.setItem("user-info", JSON.stringify(res.data.data));
        sessionStorage.setItem("token", res.data.token);
        globalContext.setLoggedIn(true);
        globalContext.setUserInfo(res.data.data);
        globalContext.setLoader(false);
        reset();
        if (location?.state?.plan) {
          navigate(`/subscriptionplan?id=${location?.state?.plan}`);
        } else {
          navigate("/");
        }
      })
      .catch((err) => {
        globalContext.setLoader(false);
        if (err.response.data.message.includes("Your email is not verified")) {
          resendLink(err.response.data.message);
        } else {
          Swal.fire("Error", err.response.data.message, "error");
        }
        console.log("Error: ", err);
      });
  };

  return (
    <section className="signup-bot">
      <div className="tab-heading-area">
        <h1 className="mb-4">Login</h1>
      </div>
      <Form onSubmit={handleSubmit(loginUser)}>
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter Email"
            {...register("email", { required: "Email is required" })}
          />
          {errors.email && (
            <span className="error-msg">{errors.email.message}</span>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter Password"
            {...register("password", {
              required: "Password is required",
            })}
          />
          {errors.password && (
            <span className="error-msg">{errors.password.message}</span>
          )}
          <p className="forgot-password-link">
            <Link to="/forgotpassword">Forgot Password?</Link>
          </p>
        </Form.Group>
        <Button variant="unset" type="submit" className="submit-btn">
          Login
        </Button>
        <p className="signup-back-btn">
          Don't have an account? <Link to="/signup">Sign up here</Link>
        </p>
      </Form>
    </section>
  );
};
