import { useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { GlobalContext } from "../context/GlobalContext";
import { VerifyUserEmail } from "../services/UserServices";

export const VerifyEmail = () => {
  const param = useParams();
  const globalContext = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    globalContext.setLoader(true);
    let params = {
      token: param.token,
    };
    VerifyUserEmail(params)
      .then((res) => {
        globalContext.setLoader(false);
        Swal.fire("Success", res.data.message, "success");
      })
      .catch((err) => {
        globalContext.setLoader(false);
        Swal.fire("Success", err.response.data.message, "error");
      });
  }, []);

  return (
    <section className="signup-bot">
      <div className="tab-heading-area">
        <h1 className="mb-4">Email verified successfully</h1>
      </div>
      <Button
        variant="unset"
        type="submit"
        className="submit-btn"
        onClick={() => navigate("/login")}
      >
        Login here
      </Button>
    </section>
  );
};
