import { Container } from "react-bootstrap";

export const AboutUs = () => {
  return (
    <Container>
      <div className="about-us-bio">
        <b>About BotFather: Pioneering Superior Crypto Trading</b>
        <div>
          Welcome to BotFather, the evolution of cryptocurrency trading
          platforms. Here, we want to share our story, our values, and our
          vision for the future.
        </div>
        <br />
        <b>Who are we?</b>
        <div>
          BotFather is the brainchild of a close-knit group of friends and
          experienced retail crypto traders. Our journey began with a shared
          passion for the dynamic world of cryptocurrencies and a mutual
          frustration over the lack of accessible, cost-effective tools
          available to the average trader.
        </div>
        <br />
        <div>
          After countless hours spent navigating clunky platforms, wrestling
          with subpar tools, and feeling the sting of missed trading
          opportunities, we decided to take matters into our own hands. From
          this collective vision and experience, BotFather was born.
        </div>
        <br />
        <div>
          Our mission is simple yet ambitious: to provide a reliable and
          innovative crypto trading platform that's accessible and affordable
          for traders at every level. We believe that everyone deserves the
          chance to succeed in their crypto trading journey, and we're committed
          to equipping them with the tools they need to thrive.
        </div>
        <br />
        <div>
          Our platform combines our extensive trading experience with
          state-of-the-art technology to offer a secure and user-friendly
          environment where traders can confidently grow and manage their
          portfolios. We're not just a trading platform; we're a community
          dedicated to empowering each other and breaking down the barriers in
          the crypto trading space.
        </div>
        <br />
        <b>Why Choose BotFather?</b>
        <div>
          Choosing BotFather means choosing an edge in your crypto trading
          journey. We offer several distinguishing features that make us a
          standout choice:
        </div>
        <ul>
          <li>
            <b>User-Friendly Interface:</b> We prioritize usability in our
            design. Our platform's intuitive layout makes navigating the world
            of crypto trading straightforward, even for beginners.
          </li>
          <li>
            <b>Prioritized Security:</b> Your security is our utmost concern. We
            implement stringent measures to protect your personal and financial
            information, ensuring complete control and safety of your assets. We
            at Botfather do not and never will have access to your private keys
            so you can rest assured knowing you are in full control.
          </li>
          <li>
            <b>Diverse Trading Tools:</b> Our platform equips traders with a
            comprehensive range of tools, including advanced trading bots, a
            unique BotDrop tool, and innovative AI-generated art tools, catering
            to a broad spectrum of crypto trading and investment strategies.
          </li>
          <li>
            <b>Transparent Pricing:</b> We firmly believe in transparency and
            fairness. Our fee structure is simple and competitive, with
            absolutely no hidden charges. We're offering double the features of
            our competitors at a fraction of the price.
          </li>
        </ul>
        <br />
        <b>Our Vision for the Future:</b>
        <div>
          BotFather's roadmap is centered around continuous improvement, meeting
          the evolving needs of our traders, and staying on the forefront of
          crypto trading technology. Here's a sneak peek into our future plans:
        </div>
        <br />
        <ul>
          <li>
            <b>Enhanced Trading Tools:</b> We're dedicated to expanding our
            array of trading tools and features, enabling traders to leverage
            comprehensive market analysis for informed decision-making.
          </li>
          <li>
            <b>Educational Resources:</b> We're developing a comprehensive
            resource hub aimed at equipping traders with the knowledge and
            skills to navigate the crypto trading landscape successfully.
          </li>
          <li>
            <b>Mobile Application:</b> We recognize the need for on-the-go
            trading capabilities. We're in the process of developing a mobile
            application for seamless, anywhere trading.
          </li>
          <li>
            <b>Upgraded Customer Support:</b> Our commitment to excellent
            customer service never wavers. We're consistently refining our
            support systems and equipping our team to deliver top-tier service
            to our traders.
          </li>
        </ul>
        <br />
        <div className="thanks-message">
          Thank you for considering BotFather. We're confident that we can
          deliver a trading experience that matches your ambition. We're excited
          to join you on your crypto trading journey, helping you realize your
          trading goals. Your future in crypto trading starts here.
        </div>
      </div>
    </Container>
  );
};
