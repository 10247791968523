import { useContext } from "react";
import "./App.css";
import "./assets/css/style.css";
import Footer from "./Component/Common/Footer";
import Header from "./Component/Common/Header";
import { Loader } from "./Component/Common/Loader";
import { Routing } from "./Component/Routing";
import { GlobalContext } from "./context/GlobalContext";

function App() {
  
  const globalContext = useContext(GlobalContext);

  return (
    <>
      {globalContext.loader && <Loader />}
      <Header />
      <Routing />
      <Footer />
    </>
  );
}

export default App;
