import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import tick from "../assets/images/tick.png";
import userpic from "../assets/images/user.png";
import { DataPagination } from "../Component/Common/DataPagination";
import { DateFormat } from "../Component/Common/DateFormat";
import { ImageLoader } from "../Component/Common/ImageLoader";
import { BASE_URL } from "../configuration/Config";
import { GlobalContext } from "../context/GlobalContext";
import {
  CancelPlan,
  EditUserProfile,
  UserData,
  MyTransactionsApi,
} from "../services/UserServices";

const ManagePlan = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const {
    register: reg,
    handleSubmit: handleCancel,
    setValue: setVal,
    reset,
    formState: { errors: errs },
  } = useForm();
  const globalContext = useContext(GlobalContext);
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [edit, setEdit] = useState(true);
  const [reasonModal, setReasonModal] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [showReason, setShowReason] = useState(false);
  const [reason, setReason] = useState("");
  const [img, setImg] = useState({ src: "", file: "" });

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    getMyTrans();
  }, [page]);

  const getUserInfo = () => {
    globalContext.setLoader(true);
    UserData()
      .then((res) => {
        setData(res.data.data);
        setValue("firstName", res.data.data.firstName);
        setValue("lastName", res.data.data.lastName);
        setValue("email", res.data.data.email);
        if (res.data?.data?.profilePic)
          setImg({ ...img, src: BASE_URL + res.data.data.profilePic });
        sessionStorage.setItem("user-info", JSON.stringify(res.data.data));
        globalContext.setUserInfo(res.data.data);
        globalContext.setLoader(false);
      })
      .catch((err) => {
        globalContext.setLoader(false);
        console.log("Error: ", err);
      });
  };

  const getMyTrans = () => {
    globalContext.setLoader(true);
    MyTransactionsApi(page - 1)
      .then((res) => {
        setTransactions(res.data.data);
        setTotalCount(res.data.totalCount);
        globalContext.setLoader(false);
      })
      .catch((err) => {
        globalContext.setLoader(false);
        console.log("error: ", err.response);
      });
  };

  const updateUser = (data) => {
    globalContext.setLoader(true);
    const formData = new FormData();
    formData.append("firstname", data.firstName.trim());
    formData.append("lastname", data.lastName.trim());
    if (img.file) formData.append("image", img.file);
    EditUserProfile(formData)
      .then((res) => {
        globalContext.setLoader(false);
        Swal.fire(
          "Success",
          "Profile data updated successfully.",
          "success"
        ).then(() => {
          setEdit(true);
          getUserInfo();
        });
      })
      .catch((err) => {
        globalContext.setLoader(false);
        if (err.response.status === 400)
          Swal.fire(err.response.data.message, "", "error");
        console.log("Error: ", err);
      });
  };

  const closeReasonModal = () => {
    setReasonModal(false);
    reset({ reason: "" });
  };

  const closeViewReasonModal = () => {
    setReason("");
    setShowReason(false);
  };

  const cancelUserSubscription = (data) => {
    globalContext.setLoader(true);
    CancelPlan(data)
      .then((res) => {
        globalContext.setLoader(false);
        closeReasonModal();
        getUserInfo();
        getMyTrans();
        Swal.fire(
          "Success",
          "Subscription plan cancelled successfully!",
          "success"
        );
      })
      .catch((err) => {
        globalContext.setLoader(false);
        closeReasonModal();
        getUserInfo();
        getMyTrans();
        console.log("Error: ", err);
        if (err.response.status === 400)
          Swal.fire(err.response.data.message, "", "error");
      });
  };

  const changeProfileImage = (file) => {
    if (file.type.includes("image")) {
      setImg({
        file: file,
        src: URL.createObjectURL(file),
      });
    } else {
      Swal.fire("Error", "Only Images are allowed", "error");
    }
  };

  return (
    <>
      <section className="tab-area">
        <Container>
          <div className="bg-plan-subscribe">
            <Row>
              <Col
                xl={6}
                md={12}
                className={`mb-0 ${data?._subscription ? "" : "have-plan"}`}
              >
                <div className="user-info">
                  <div className="card-heading">
                    <h6>User Information</h6>

                    <div className="editchange">
                      {edit ? (
                        <a onClick={() => setEdit(false)}>
                          <i className="fa fa-pencil"></i>Edit
                        </a>
                      ) : (
                        <a onClick={() => handleSubmit(updateUser)()}>Save</a>
                      )}
                      <Button
                        type="button"
                        className="change-pass"
                        variant="unset"
                        onClick={() => navigate("/changepassword")}
                      >
                        Change Password
                      </Button>
                    </div>
                  </div>

                  <hr />

                  <div className="userinfo">
                    <Row>
                      <Col xl="12" md="12" xs="12">
                        <div className="profile-pic">
                          <ImageLoader image={img.src ? img.src : userpic} width={150} height={150} />
                          <input
                            type="file"
                            className="choose-file"
                            accept="image/*"
                            onChange={(e) =>
                              changeProfileImage(e.target.files[0])
                            }
                            disabled={edit}
                          />
                        </div>
                      </Col>

                      <Col xl="12" md="12" xs="12">
                        <Form>
                          <Row>
                            <Col xl="6" md="6" xs="12">
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicpass"
                              >
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter First Name"
                                  maxLength={15}
                                  {...register("firstName", {
                                    onChange: (e) => {
                                      if (e.target.value === " ") {
                                        setValue("firstName", "");
                                      }
                                    },
                                    required: "First name is required",
                                  })}
                                  disabled={edit}
                                />
                                {errors.firstName && (
                                  <span className="error-msg">
                                    {errors.firstName.message}
                                  </span>
                                )}
                              </Form.Group>
                            </Col>

                            <Col xl="6" md="6" xs="12">
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicpass"
                              >
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Last Name"
                                  maxLength={15}
                                  {...register("lastName", {
                                    onChange: (e) => {
                                      if (e.target.value === " ") {
                                        setValue("lastName", "");
                                      }
                                    },
                                    required: "Last name is required",
                                  })}
                                  disabled={edit}
                                />
                                {errors.lastName && (
                                  <span className="error-msg">
                                    {errors.lastName.message}
                                  </span>
                                )}
                              </Form.Group>
                            </Col>

                            <Col xl="12" md="12" xs="12">
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicpass"
                              >
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Email Address"
                                  {...register("email", {
                                    onChange: (e) => {
                                      if (e.target.value === " ") {
                                        setValue("email", "");
                                      }
                                    },
                                    pattern: {
                                      value:
                                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                                      message: "Enter valid email address",
                                    },
                                    required: "Email is required",
                                  })}
                                  disabled
                                />
                                {errors.email && (
                                  <span className="error-msg">
                                    {errors.email.message}
                                  </span>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                          {!edit && (
                            <Button
                              variant="unset"
                              className="ms-auto update"
                              onClick={() => setEdit(true)}
                            >
                              Cancel
                            </Button>
                          )}
                        </Form>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>

              {data?._subscription && (
                <Col xl="6">
                  <>
                    <div className="user-detail">
                      <div className="card-heading">
                        <h6>{data?._subscription.planName} </h6>
                        <h3>
                          Next Billing Period:- {DateFormat(data?.planExpiry)}
                        </h3>
                      </div>
                      {data?.isTrial === "1" && (
                        <span className="trial-period">
                          Free trial available for 48 hours
                        </span>
                      )}
                      <hr />

                      <Row>
                        <div className="user-plan">
                          <div className="card-heading">
                            <h2>
                              ${data?.planPrice}/
                              <span>{data?._subscription?.durationUnit}</span>
                            </h2>
                            <Button
                              type="button"
                              variant="unset"
                              onClick={() => setReasonModal(true)}
                            >
                              Cancel Subscription
                            </Button>
                          </div>
                          <ul>
                            {data._subscription.benifits.map((b, i) => (
                              <li key={i}>
                                <img src={tick} alt="tick" />
                                {b}
                              </li>
                            ))}
                          </ul>

                          <Button
                            type="button"
                            variant="unset"
                            className="btn btn-software"
                          >
                            <i className="fa fa-download"></i>Download Software
                          </Button>
                        </div>
                      </Row>
                      <div className="disclaimer">
                        <h4>Disclaimer</h4>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          Lorem Ipsum is simply dummy text...
                        </p>
                      </div>
                    </div>
                  </>
                </Col>
              )}
            </Row>

            {transactions.length > 0 && (
              <div className="user-detail mt-0 transaction-history-main">
                <div className="col-lg-12 col-12  bg-black user-table">
                  <div className="table-main subscription">
                    <div className="main-table-box table-responsive userlist">
                      <div className="align-items-center subscribe-main">
                        <div className="heading-top text-left">
                          <div className="card-heading">
                            <h2>My Transaction History</h2>
                          </div>
                          <hr />
                        </div>
                      </div>
                      <table className="table py-5 mt-3">
                        <thead>
                          <tr>
                            <th scope="col">Paypal Subscription ID</th>
                            <th scope="col">Subscription Plan</th>
                            <th scope="col">Plan Price</th>
                            <th scope="col">Price Paid</th>
                            <th scope="col">Purchased On</th>
                            <th scope="col">Expiry Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactions.map((t, j) => (
                            <tr key={j}>
                              <td>{t.paypalSubscriptionId}</td>
                              <td>{t._subscription?.planName}</td>
                              <td>
                                ${t.planPrice}/{t._subscription?.durationUnit}
                              </td>
                              <td>${t?.amountPaid ? t.amountPaid : "0"}</td>
                              <td>{DateFormat(t.startTime)}</td>
                              <td>
                                {t.status === "CANCELLED" ? (
                                  <span>
                                    {`Cancelled ${
                                      t.cancelledBy === "U" ? "" : "by Admin"
                                    }`}{" "}
                                    <span
                                      className="view-reason"
                                      onClick={() => {
                                        setReason(t.cancelReason);
                                        setShowReason(true);
                                      }}
                                    >
                                      View Reason
                                    </span>
                                  </span>
                                ) : new Date(t.expireAt) < new Date() ? (
                                  "Expired"
                                ) : (
                                  DateFormat(t.expireAt)
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {totalCount > 10 && (
                        <DataPagination
                          page={page}
                          totalItems={totalCount}
                          changePage={(e) => setPage(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Container>

        <Modal
          className="view-reason"
          show={showReason}
          onHide={() => closeViewReasonModal()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Cancelation Reason</Modal.Title>
          </Modal.Header>
          <Modal.Body>{reason}</Modal.Body>
        </Modal>
        <Modal
          show={reasonModal}
          onHide={() => closeReasonModal()}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>Cancelation Reason</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter reason here..."
              maxLength={128}
              {...reg("reason", {
                onChange: (e) => {
                  if (e.target.value.trim() === "") {
                    setVal("reason", "");
                  }
                },
                required: "Reason is required",
              })}
            />
            {errs.reason && (
              <span className="error-msg">{errs.reason.message}</span>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => closeReasonModal()}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={handleCancel(cancelUserSubscription)}
            >
              Cancel Subscription
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </>
  );
};
export default ManagePlan;
