import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useContext } from "react";
import { GlobalContext } from "../context/GlobalContext";
import { ResetPasswordApi } from "../services/UserServices";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";

export const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();
  const globalContext = useContext(GlobalContext);
  const [query] = useSearchParams();
  const navigate = useNavigate();

  const resetPass = (data) => {
    if (data.newPassword !== data.confirmPassword) {
      setError("confirmPassword", {
        message: "Password and confirm password should be same",
      });
    } else {
      globalContext.setLoader(true);
      data["token"] = query.get("token");
      delete data.confirmPassword;
      ResetPasswordApi(data)
        .then((res) => {
          globalContext.setLoader(false);
          Swal.fire("Success", "Password reset successfully.", "success").then(
            () => navigate("/login")
          );
          reset();
        })
        .catch((err) => {
          globalContext.setLoader(false);
          Swal.fire("Error", err.response.data.message, "error").then(() =>
            navigate("/login")
          );
          console.log("Error: ", err);
        });
    }
  };

  return (
    <section className="signup-bot">
      <div className="login  m-auto">
        <div className="tab-heading-area">
          <h1 className="mb-4">Reset Password</h1>
        </div>
        <Form onSubmit={handleSubmit(resetPass)}>
          <div className="row">
            <div className="login-data col-lg-12">
              <Form.Group className="mb-3" controlId="formBasicpass">
                <Form.Control
                  type="password"
                  placeholder="New Password"
                  {...register("newPassword", {
                    onChange: (e) => {
                      if (e.target.value === " ") {
                        setValue("newPassword", "");
                      }
                    },
                    required: "Password is required",
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])(?!.*\s).{8,}$/,
                      message:
                        "Password must contain 1 lower case, 1 upper case, 1 number and 1 special character.",
                    },
                    minLength: {
                      value: 8,
                      message: " Password must be at least 8 characters long",
                    },
                  })}
                />
                {errors.newPassword && (
                  <span className="error-msg">
                    {errors.newPassword.message}
                  </span>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicpass">
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  {...register("confirmPassword", {
                    onChange: (e) => {
                      if (e.target.value === " ") {
                        setValue("confirmPassword", "");
                      }
                    },
                    required: "Confirm password is required",
                  })}
                />
                {errors.confirmPassword && (
                  <span className="error-msg">
                    {errors.confirmPassword.message}
                  </span>
                )}
              </Form.Group>
            </div>

            <div className="col-lg-12 login-data">
              <button className="btn btn-sign">Submit</button>
            </div>
          </div>
        </Form>
      </div>
    </section>
  );
};
