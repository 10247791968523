import React, { useContext, useEffect, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import { GetSubscriptionsPlan, UserData } from "../services/UserServices";
import { PlanData } from "../Component/Common/PlanData";

export const Home = () => {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const globalContext = useContext(GlobalContext);
  const [duration, setDuration] = useState(
    query.get("plan") ? query.get("plan") : "Yearly"
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    getSubscriptionPlans();
    if (globalContext.loggedIn) getUserInfo();
  }, [duration]);

  const getUserInfo = () => {
    UserData()
      .then((res) => {
        sessionStorage.setItem("user-info", JSON.stringify(res.data.data));
        globalContext.setUserInfo(res.data.data);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const getSubscriptionPlans = () => {
    globalContext.setLoader(true);
    GetSubscriptionsPlan(duration)
      .then((res) => {
        setData(res.data.data);
        globalContext.setLoader(false);
      })
      .catch((err) => {
        globalContext.setLoader(false);
        console.log("error: ", err);
      });
  };

  return (
    <>
      <section className="tab-area">
        <Container>
          <div className="tab-heading-area">
            <h1>Choose the Plan that Works Best for You</h1>
            <p>At BotFather, we believe in flexibility and value. That's why we've designed two straightforward pricing plans,<br />
              so you can select the one that best aligns with your trading needs and financial goals.</p>
          </div>

          <div className="tab-content-plans">
            <Tabs
              activeKey={duration}
              onSelect={(e) => {
                setDuration(e);
                navigate({ search: `?plan=${e}` });
              }}
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="Yearly" title="Yearly Plans">
                <div className="plans-heading">
                  <h3>Annual Plan - $324/year (10% Discount) </h3>
                  <p>With our Annual Plan, you get all the benefits of the Monthly Plan, but at a 10% discounted rate.
                    It's perfect for those who are committed to long-term trading and want to make the most of BotFather's extensive features, all year round.</p>
                </div>
                {data.length ? (
                  <PlanData data={data} />
                ) : (
                  <h4 className="text-center text-white">No data found</h4>
                )}
              </Tab>
              <Tab eventKey="Monthly" title="Monthly Plans">
              <div className="plans-heading">
                  <h3>Monthly Plan - $30/month </h3>
                  <p>Our Monthly Plan offers you full access to all of BotFather's powerful features and tools, providing you the agility to adapt to the dynamic 
                    world of crypto trading. Enjoy complete access to our automated trading bots, unique BotDrop tool, AI-generated art tools, and more. 
                    The flexibility of this plan allows you to cancel at any time without any hassle.</p>
                </div>
                {data.length ? (
                  <PlanData data={data} />
                ) : (
                  <h4 className="text-center text-white">No data found</h4>
                )}
              </Tab>
            </Tabs>
            
            <p className="color-white text-center">* Each plan ensures complete access to our robust suite of tools, designed to maximize your <br/>trading potential and profitability. 
                    At BotFather, we're as invested in your success as you are.</p>
            <p className="color-white text-center pt-2"><i>Remember, your future in crypto trading starts here.</i></p>
          </div>
        </Container>
      </section>
    </>
  );
};
